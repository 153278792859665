import { ParseInt } from './decorators';
import Patient from "../models/Patient";
import Practitioner from "../models/Practitioner";
import {ClassTransformOptions, plainToClass, Type} from "class-transformer";
import Guard from '../enums/Guard';
import Timezone from '../enums/Timezone';
import Admin from '../models/Admin';

class App {

    id: number;
    currentRouteName: string;
    appTimezone: Timezone;
    timestampMs: number;
    verification_took_place: number;
    @ParseInt maxFilesize: number;

    /*
    |--------------------------------------------------------------------------
    | Relationships
    |--------------------------------------------------------------------------
    */

    @Type(() => Practitioner)
    practitioner: Practitioner

    @Type(() => Patient)
    patient: Patient

    @Type(() => Admin)
    admin: Admin


    /*
    |--------------------------------------------------------------------------
    | Methods
    |--------------------------------------------------------------------------
    */

    /**
     * Get max filesize in MB
     *
     * @returns {number}
     */
    maxFilesizeInMB(): number {
        return this.maxFilesize / 1024 / 1024;
        // or
        // return bytesToUnit(this.maxFilesize, DataUnit.MB);
    }

    /**
     * Check if application guard is the given
     *
     * @param value
     * @returns {boolean}
     */
    guardIs(value: Guard): boolean {
        return this.guard(value);
    }

    /**
     * Check if a verfication just took place
     *
     * @returns {boolean}
     */
    verificationJustTookPlace(): boolean {
        return this.verification_took_place === 1;
    }

    /**
     * Get the current application guard.
     *
     * @return {string}
     */
    guard(): Guard;
    guard(value: Guard): boolean;
    guard(value?: Guard): Guard | boolean {
        if (value === undefined) {
            return window._app.guard;
        }
        return window._app.guard === value;
    }

    /**
     * Converts plain (literal) object to class (constructor) object. Also works with arrays.
     */
    static new(options?: ClassTransformOptions): App {
        return plainToClass(App, window._app, options);
    }
}

export default App;
