/**
 * Normaly, it should be named AppointmentStage
 * but to avoid conflicts with AppointmentStage model,
 */
enum Stage {

    STAGELESS   = 'stageless',

    CHECKED_IN  = 'checked_in',

    IN_PROGRESS = 'in_progress',

    CHECKED_OUT = 'checked_out',
}

export default Stage;
