import Model from "./Model";
import CityTranslation from "./CityTranslation";
import {ClassTransformOptions, plainToClass, Type} from "class-transformer";

class City extends Model {

    id: number;
    uuid: string;
    slug: string;
    lat: number;
    lng: number;
    place_id: string;
    postal_code: string;
    population: number;
    @Type(() => Date)
    created_at: Date;
    @Type(() => Date)
    updated_at: Date;


    /*
    |--------------------------------------------------------------------------
    | Reflection
    |--------------------------------------------------------------------------
    */

    /**
     * Gets class name
     * survives minification/uglification
     *
     * @return {string}
     */
    getClassName(): string {
        return 'City';
    }


    /*
    |--------------------------------------------------------------------------
    | Relationships
    |--------------------------------------------------------------------------
    */

    @Type(() => CityTranslation)
    translation: CityTranslation;

    @Type(() => CityTranslation)
    translations: CityTranslation[];


    /*
    |--------------------------------------------------------------------------
    | Methods
    |--------------------------------------------------------------------------
    */

    name(locale?: string): string {

        return (this as any).translateOrDefault(locale)?.name;
    }

    /**
     * Converts plain (literal) object to class (constructor) object. Also works with arrays.
     */
     static new(plain: [], options?: ClassTransformOptions): City[];
     static new(plain: {}, options?: ClassTransformOptions): City;
     static new(plain: {} | [], options?: ClassTransformOptions): City | City[] {
         return plainToClass(City, plain as any, options);
     }
}

export default City;
