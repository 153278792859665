import {ClassTransformOptions, plainToClass, Transform, Type} from "class-transformer";
import MotiveTranslation from "./MotiveTranslation";
import Speciality from "./Speciality";
import Translatable from "../mixins/Translatable";
import Model from "./Model";
import {applyMixins} from "../support/utils";

class Motive extends Model {

    id: number;
    uuid: string;
    slug: string;
    speciality_id: number;
    @Type(() => Date)
    created_at: Date;
    @Type(() => Date)
    updated_at: Date;

    /**
     * it's not an actual field in the motive table
     * but it's used as flag to indicate if the motive is
     * (at the moment of writing this comment) selected in filter
     * could be used in the future to indicate some other state, w'll see
     */
    // @Transform(({ value }) => value || false, { toClassOnly: true })
    // selected: boolean;

    /*
    |--------------------------------------------------------------------------
    | Reflection
    |--------------------------------------------------------------------------
    */

    /**
     * Gets class name
     * survives minification/uglification
     *
     * @return {string}
     */
    getClassName(): string {
        return 'Motive';
    }

    /*
    |--------------------------------------------------------------------------
    | Relationships
    |--------------------------------------------------------------------------
    */

    @Type(() => Speciality)
    speciality: Speciality;

    @Type(() => MotiveTranslation)
    translation: MotiveTranslation;

    @Type(() => MotiveTranslation)
    translations: MotiveTranslation[];

    /*
    |--------------------------------------------------------------------------
    | Methods
    |--------------------------------------------------------------------------
    */

    /**
     * Get motive name
     *
     * @param {string} [locale]
     * @return {string}
     */
    name(locale?: string) {

        return (this as any).translateOrDefault(locale)?.name;
    }

    /**
     * Get speciality name
     *
     * @param {string} [locale]
     * @return {string}
     */
    specialityName(locale?: string) {

        return this.speciality?.name(locale);
    }

    /**
     * Converts plain (literal) object to class (constructor) object. Also works with arrays.
     */
    static new(plain: [], options?: ClassTransformOptions): Motive[];
    static new(plain: {}, options?: ClassTransformOptions): Motive;
    static new(plain: {} | [], options?: ClassTransformOptions): Motive | Motive[] {
        return plainToClass(Motive, plain as any, options);
    }
}

export default Motive;
