import Model from './Model';
import Practitioner from './Practitioner';
import Establishment from './Establishment';
import { ClassTransformOptions, plainToClass, Type } from 'class-transformer';

class PractitionerSetting extends Model {
    id: number;
    practitioner_id: number;
    establishment_id: number;
    key: string;
    value: string;
    data_type: string;
    @Type(() => Date)
    created_at: Date;
    @Type(() => Date)
    updated_at: Date;


    /*
    |--------------------------------------------------------------------------
    | Reflection
    |--------------------------------------------------------------------------
    */

    /**
     * Gets class name
     * survives minification/uglification
     *
     * @return {string}
     */
    getClassName(): string {
        return 'PractitionerSetting';
    }


    /*
    |--------------------------------------------------------------------------
    | Relationships
    |--------------------------------------------------------------------------
    */

    @Type(() => Practitioner)
    practitioner: Practitioner;

    @Type(() => Establishment)
    establishment: Establishment;


    /*
    |--------------------------------------------------------------------------
    | Methods
    |--------------------------------------------------------------------------
    */

    /**
     * Created date
     *
     * @param {string} format
     * @return {string}
     */
    createdDate(format: string = 'DD MMMM YYYY'): string {
        return window.moment(this.created_at).format(format);
    }

    /**
     * Created time
     *
     * @param {string} format
     * @return {string}
     */
    createdTime(format: string = 'HH[h]mm'): string {
        return window.moment(this.created_at).format(format);
    }

    /**
     * Converts plain (literal) object to class (constructor) object. Also works with arrays.
     */
    static new(plain: [], options?: ClassTransformOptions): PractitionerSetting[];
    static new(plain: {}, options?: ClassTransformOptions): PractitionerSetting;
    static new(plain: {} | [], options?: ClassTransformOptions): PractitionerSetting | PractitionerSetting[] {
        return plainToClass(PractitionerSetting, plain as any, options);
    }
}

export default PractitionerSetting;
