import lang from '../../lang';
import Env from '../enums/Env';
import CryptoJS from 'crypto-js';
import { Ziggy } from '../../ziggy';
import Model from '../models/Model';
import Locale from '../enums/Locale';
import { Replacements } from 'lang.js';
import Patient from '../models/Patient';
import Timezone from '../enums/Timezone';
import intlTelInput from 'intl-tel-input';
import { DropzoneOptions } from 'dropzone';
import HttpStatus from '../enums/HttpStatus';
import Practitioner from '../models/Practitioner';
import { RouteParamsWithQueryOverload } from 'ziggy-js';
import Router from '../../../../../vendor/tightenco/ziggy/src/js/Router';
import { getProperty, isNumeric, isServerError, responseIsOk } from './utils';
import { Moment, MomentFormatSpecification, MomentInput } from 'moment-timezone';
import { Alphanumeric, Toast, TokBoxConfig, ShowPractitionerRoutePrefix } from './types';
import Admin from '../models/Admin';

/**
 * Get practitioner
 *
 * @param {string|null} attribute
 * @return {Practitioner}
 */
export function getPractitioner(): Practitioner;
export function getPractitioner(attribute: string): any;
export function getPractitioner(attribute?: string): Practitioner | any {
    return attribute ? window.app.practitioner[attribute] : window.app.practitioner;
}

/**
 * Get patient
 *
 * @param {string|null} attribute
 * @return {Patient}
 */
export function getPatient(): Patient;
export function getPatient(attribute: string): any;
export function getPatient(attribute?: string): Patient|any {
    return attribute ? window.app.patient[attribute] : window.app.patient;
}

/**
 * Get admin
 *
 * @param {string|null} attribute
 * @return {Admin}
 */
 export function getAdmin(): Admin;
 export function getAdmin(attribute: string): any;
 export function getAdmin(attribute?: string): Admin|any {
     return attribute ? window.app.admin[attribute] : window.app.admin;
 }

/**
 * Generate an asset path.
 *
 * @param {string} path
 * @return {string}
 */
export function asset(path?: string): string {
    return baseUrl(path);
}

/**
 * Get base url
 *
 * @param {string} path
 * @param {boolean} localized
 * @param {string} lang
 * @return {string}
 */
export function baseUrl(path?: string, localized: boolean = false, lang?: string): string {
    const host = window._app.schemeAndHttpHost as string;

    const _locale = lang ? lang : currentLocale();

    const base_url = localized ? `${host}${DS}${_locale}` : host;

    const pathname = path && !path.startsWith('?') ? `${DS}${path}` : path;

    return path ? `${base_url}${pathname}` : base_url;
}

/**
 * Directory separator
 *
 * @type {string}
 */
export const DS: string = '/';

/**
 * Check the current route
 *
 * @param {string} name
 * @param {RouteParamsWithQueryOverload} [params]
 * @return {string|undefined|boolean}
 */
export function currentRoute(): string | undefined;
export function currentRoute(name: string, params?: RouteParamsWithQueryOverload): boolean;
export function currentRoute(name?: string, params?: RouteParamsWithQueryOverload): string | undefined | boolean {
    return route().current(name, params);
}

/**
 * Check the current localized route
 *
 * @param {string} name
 * @param {RouteParamsWithQueryOverload} [params]
 * @return {string|undefined|boolean}
 */
export function currentLocalizedRoute(): string | undefined;
export function currentLocalizedRoute(name: string, params?: RouteParamsWithQueryOverload): boolean;
export function currentLocalizedRoute(name?: string, params?: RouteParamsWithQueryOverload): string | undefined | boolean {
    return localizedRoute().current(name, params);
}

/**
 * Localized ziggy route
 *
 * @param  {string} name - Route name.
 * @param  {RouteParamsWithQueryOverload} params - Route parameters.
 * @param  {boolean} absolute - Whether to include the URL origin.
 * @return {Function}
 */
export function localizedRoute(name?: undefined, params?: RouteParamsWithQueryOverload|Alphanumeric, absolute?: boolean): Router;
export function localizedRoute(name: string, params?: RouteParamsWithQueryOverload|Alphanumeric, absolute?: boolean): string;
export function localizedRoute(name: string, params?: RouteParamsWithQueryOverload|Alphanumeric, absolute: boolean = true): Router | string {
    return route(name, params, absolute, true);
}

/**
 * Ziggy route
 * @see vendor/tightenco/ziggy/src/js/Router.js
 *
 * @param  {string} name - Route name.
 * @param  {RouteParamsWithQueryOverload} params - Route parameters.
 * @param  {boolean} absolute - Whether to include the URL origin.
 * @param  {boolean} localized
 * @return {string}
 */
export function route(name?: undefined, params?: RouteParamsWithQueryOverload|Alphanumeric, absolute?: boolean, localized?: boolean): Router;
export function route(name: string, params?: RouteParamsWithQueryOverload|Alphanumeric, absolute?: boolean, localized?: boolean): string;
export function route(name: string, params: RouteParamsWithQueryOverload|Alphanumeric, absolute: boolean = true, localized: boolean = false): Router|string {
    if (localized && Ziggy.url.indexOf(currentLocale()) === -1) {
        Ziggy.url = Ziggy.url + DS + currentLocale();
    }

    let router = new Router(name, params, absolute, Ziggy);

    return name ? router.toString() : router;
}

/**
 * Get the locale prefix.
 *
 * @return {string}
 */
export function practitionerRoutePrefix(locale?: Locale): ShowPractitionerRoutePrefix {
    // @ts-ignore
    return {'fr' : 'medecin', 'ar' : 'tabib'}[locale ?? currentLocale()];
}

/**
 * Check if given locale is current locale
 *
 * @param {string} [locale]
 * @returns {boolean}
 */
export function currentLocaleIs(locale: string): boolean {
    return window._app.locale === locale;
}

/**
 * Get current locale
 *
 * @returns {string}
 */
export function currentLocale(): string {
    return window._app.locale;
}

/**
 * Translate the given string.
 *
 * @param {string} key
 * @returns {string|null}
 */
export function __(key: string, replacements?: Replacements, locale?: string): string {
    return lang.strings(key, replacements, locale);
}

/**
 * translation for validation rules
 *
 * @param {string} rule
 * @param {string|object} attribute
 * @return {string}
 */
export function __v(rule: string, attribute?: any): string {
    return lang.validation(rule, attribute);
}

/**
 * Translate the given text.
 *
 * @param {string} key
 * @returns {string|null}
 */
 export function __t(key: string, replacements?: Replacements, locale?: string): string {
    return lang.get(key, replacements, locale);
}

/**
 * Fail handler for ajax requests.
 */
export type FailHandlerParams = {
    caseOk?: (response?: JQueryXHR) => void;
    caseNotFound?: (response?: JQueryXHR) => void;
    caseForbidden?: (response?: JQueryXHR) => void;
    caseUnauthorized?: (response?: JQueryXHR) => void;
    caseBadRequest?: (response?: JQueryXHR) => void;
    caseUnprocessableEntity?: (response?: JQueryXHR) => void;
    caseConflict?: (response?: JQueryXHR) => void;
    caseInternalServerError?: (response?: JQueryXHR) => void;
    caseServiceUnavailable?: (response?: JQueryXHR) => void;
    caseGatewayTimeout?: (response?: JQueryXHR) => void;
    caseUnknown?: (response?: JQueryXHR) => void;
    caseDefault?: (response?: JQueryXHR) => void;
};

/**
 * Standard (Ajax) fail handler
 *
 * @param {FailHandlerParams} params
 * @returns {(response: any) => void}
 */
export function failHandler(params?: FailHandlerParams): (response: any) => void {
    return (response: any) => {
        const {
            caseOk,
            caseInternalServerError,
            caseForbidden,
            caseDefault,
            caseNotFound
        } = params || {};

        if (responseIsOk(response.status)) {
            caseOk && caseOk(response);
            return;
        }

        if (isServerError(response.status)) {
            if (caseInternalServerError) {
                caseInternalServerError(response);
                return;
            }
            toast(__('error_message_5xx')).error();
            return;
        }

        if (response.status === HttpStatus.FORBIDDEN) {
            if (caseForbidden) {
                caseForbidden(response);
                return;
            }
            toast(__('access_denied')).error()
            return;
        }

        if (response.status === HttpStatus.NOT_FOUND) {
            if (caseNotFound) {
                caseNotFound(response);
                return;
            }
            toast(__('resource_not_found')).error()
            return;
        }

        if (caseDefault) {
            caseDefault(response);
            return;
        }

        validationErrors(response).forEach(function (message: string) {
            toast(message).error();
        });
    };
}

/**
 * Dropzone instance wrapper
 *
 * @param {string|HTMLElement} container
 * @param {DropzoneOptions} options
 * @returns {Dropzone}
 */
export function dropzone(container: string | HTMLElement, options: DropzoneOptions): Dropzone {

    const defaults = {
        dictDefaultMessage: __('dropzone_dictDefaultMessage'),
        dictFallbackMessage: __('dropzone_dictFallbackMessage'),
        dictFallbackText: __('dropzone_dictFallbackText'),
        dictFileTooBig: __('dropzone_dictFileTooBig'),
        dictInvalidFileType: __('dropzone_dictInvalidFileType'),
        dictResponseError: __('dropzone_dictResponseError'),
        dictCancelUpload: __('dropzone_dictCancelUpload'),
        dictCancelUploadConfirmation: __('dropzone_dictCancelUploadConfirmation'),
        dictRemoveFile: __('dropzone_dictRemoveFile'),
        dictMaxFilesExceeded: __('dropzone_dictMaxFilesExceeded'),
    };

    const _options = Object.assign({}, defaults, options);

    // @ts-ignore
    return new Dropzone(container, _options);
}

/**
 * Toast notification
 *
 * @param {string} text
 * @param {Noty.Options} config
 * @return {Toast}
 */
export function toast(text: string, config: Noty.Options = {}): Toast {

    let defaults = {
        text: text,
        layout: 'bottomRight',
        timeout: 5000,
    };

    let options: Noty.Options = Object.assign({}, defaults, config);

    let noty = new window.Noty(options);

    const optionsOverride = true;

    return {
        instance: noty,

        success: function () {
            return noty.setType('success', optionsOverride).show();
        },

        error: function () {
            return noty.setType('warning', optionsOverride).show();
        },
    };
}

/**
 * Extract validation errors from response
 *
 * @param {Object} response
 * @return {string[]}
 */
export function validationErrors(response: any): string[] {
    return Object.values(response.responseJSON.errors).flat() as string[];
}

/**
 * Add shadow pulse to element
 *
 * @param {Element | string} el
 * @param {number} timeout
 */
export function shadowPulse(element: Element | string, timeout: number = 4000): void {
    const el = typeof element === 'string' ? document.querySelector(element) : element;

    el.classList.add('pulsy');

    window.setTimeout(() => el.classList.remove('pulsy'), timeout);
}

/**
 * Add shadow pulse to element
 *
 * @param {Element | string} el
 * @param {number} timeout
 */
export function shadowDangerPulse(element: Element | string, timeout: number = 4000): void {
    const el = typeof element === 'string' ? document.querySelector(element) : element;

    el.classList.add('danger-pulse');

    window.setTimeout(() => el.classList.remove('danger-pulse'), timeout);
}

/**
 * Extract id
 *
 * @param {Model|number|string} subject
 * @returns {number}
 */
export function parseId(subject: Model | number | string): number {
    // we check if subject is a numeric value,
    // because it's not always guaranteed that subject when passed as number is an actual integer
    // I added the "+" to cast any number to an actual integer, why is this necessary? to pass the strict type check "==="
    // @ts-ignore
    return isNumeric(subject) ? +subject : +subject.id;
}

/**
 * Determine if the application is in the production environment.
 *
 * @return {boolean}
 */
export function isProduction(): boolean {
    return window._app.env === Env.PRODUCTION;
}

/**
 * set/get "request existence" status
 *
 * @param {boolean} flag
 */
export function requestInProgress(flag: boolean): void;
export function requestInProgress(flag?: boolean): boolean;
export function requestInProgress(flag?: boolean): boolean|void {
    if (flag === undefined) {
        return window.requestInProgress;
    }
    window.requestInProgress = flag;
}

/**
 * Check if the given date is today
 *
 * @param {Date|string} subject
 * @returns {boolean}
 */
export function isToday(subject: MomentInput, timezone?: Timezone): boolean {
    timezone       = timezone || Timezone.AFRICA_CASABLANCA;
    const today    = now(timezone);
    const date     = moment(subject, timezone);
    return date.isSame(today, 'day');
}

/**
 * Return the current time
 *
 * @param {Timezone} timezone
 * @returns {Moment}
 */
export function now(timezone?: Timezone): Moment {
    timezone = timezone || Timezone.AFRICA_CASABLANCA;
    return moment().tz(timezone);
}

/**
 * Timezoned momentjs instance
 *
 * @param {MomentInput} inp
 * @param {Timezone} timezone
 * @param {MomentFormatSpecification} format
 * @param {boolean} strict
 * @returns {Moment}
 */
export function moment(inp?: MomentInput): Moment;
export function moment(inp?: MomentInput, timezone?: Timezone): Moment;
export function moment(inp?: MomentInput, timezone?: Timezone, format?: MomentFormatSpecification): Moment;
export function moment(inp?: MomentInput, timezone?: Timezone, format?: MomentFormatSpecification, strict?: boolean): Moment {

    timezone = timezone || Timezone.AFRICA_CASABLANCA;

    switch (arguments.length) {
        case 0:
            return window.moment().tz(timezone);
            break;
        case 1:
            return window.moment(inp).tz(timezone);
            break;
        case 2:
            return window.moment(inp).tz(timezone);
            break;
        case 3:
            return window.moment(inp, format).tz(timezone);
            break;
        case 4:
            return window.moment(inp, format, strict).tz(timezone);
            break;
        default:
            break;
    }
}

/**
 * Get tokbox data
 *
 * @param  {TokBoxConfig[K]} [key]
 * @return {TokBoxConfig|TokBoxConfig[K]}
 */
export function tokbox(): TokBoxConfig;
export function tokbox<T extends TokBoxConfig, K extends keyof TokBoxConfig>(key: T[K]): TokBoxConfig[K];
export function tokbox<T extends TokBoxConfig, K extends keyof TokBoxConfig>(key?: T[K]): TokBoxConfig|TokBoxConfig[K] {

    var data = window._app.tokbox;

    return key ? getProperty(data, key) : data;
}

/**
 * Init intl tel input
 *
 * @param {Element} input
 * @return {*}
 */
export function initIntlInput(input: Element|string, options?: intlTelInput.Options): intlTelInput.Plugin {
    const element = typeof input === 'string' ? document.querySelector(input) : input;
    if (! element) return;
    const defaults = {
        placeholderNumberType: guessPhoneNumberType(element as HTMLInputElement),
        initialCountry: '',
        preferredCountries: ['ma', 'fr'],
        excludeCountries: ['eh'],
        // geoIpLookup: function(callback) {
        // 	$.get('https://ipinfo.io', function() {}, "jsonp").always(function(resp) {
        // 		var countryCode = (resp && resp.country) ? resp.country : "";
        // 		callback(countryCode);
        // 	});
        // },
        utilsScript: require('@public/js/utils'),
    };

    return intlTelInput(element, {...defaults, ...options});
}

/**
 * Get intl instance from input element
 *
 * @param {HTMLElement|string} input
 * @return {intlTelInput.Plugin}
 */
export function intlInstance(node: HTMLElement|string): intlTelInput.Plugin {
    const input = typeof node === 'string' ? document.querySelector(node) : node;
    return window.intlTelInputGlobals.getInstance(input);
}

/**
 * Guess phone number type base on element name
 * @see https://github.com/jackocnr/intl-tel-input/blob/master/src/js/utils.js#L114
 *
 * @param {HTMLInputElement} input
 * @return {string}
 */
function guessPhoneNumberType(input: HTMLInputElement): intlTelInput.placeholderNumberType {
    let needle   = input.name.toLowerCase();
    let haystack = ['mobile'];
    return haystack.includes(needle) ? 'MOBILE' : 'FIXED_LINE';
}

/**
 * Animate css
 *
 * @param {HTMLElement|string} element
 * @param {string} animation
 * @param {string} prefix
 */
export function animateCSS(element: HTMLElement|string, animation: string, prefix?: string): Promise<string> {
    prefix = prefix || '';
    // We create a Promise and return it
    return new Promise((resolve, reject) => {
        const animated = `${prefix}animated`;
        const animationName = `${prefix}${animation}`;
        const node = typeof element === 'string' ? document.querySelector(element) : element;

        node.classList.add(animated, animationName);

        // When the animation ends, we clean the classes and resolve the Promise
        function handleAnimationEnd(event) {
            event.stopPropagation();
            node.classList.remove(animated, animationName);
            resolve('animationend');
        }

        node.addEventListener('animationend', handleAnimationEnd, { once: true });
    });
}


/**
 * Animate css
 *
 * @param {HTMLElement|string} node
 * @param {string} animationClass
 * @param {number} duration
 */
 export function beat(node: HTMLElement|string, animationClass: string, duration?: number): Promise<string> {
    duration       = duration || 0;
    animationClass = animationClass || 'pulsy';

    return new Promise((resolve, reject) => {
        const element = typeof node === 'string' ? document.querySelector(node) : node;

        if (!element) {
            return reject('No element found');
        }

        element.classList.add(animationClass);

        // When the animation ends, we clean the classes and resolve the Promise
        function handleAnimationEnd(event) {
            wait(duration).then(() => {
                event.stopPropagation();
                element.classList.remove(animationClass);
                resolve('animationend');
            });
        }

        element.addEventListener('animationend', handleAnimationEnd, { once: true });
    });
}

/**
 * setTimeout promizified
 *
 * @param {number} ms
 * @return {Promise<void>}
 */
export function wait(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * 320 x 50 Ad banner
 *
 * @returns {string}
 */
export function render320x50Ad(): string {
    return `
        <div class="ads-footer">
            <!-- SQUARE 320x50 -->
            <ins class="adsbygoogle"
                style="display:inline-block;width:320px;height:50px"
                data-ad-client="ca-pub-3055942217975997"
                data-ad-slot="9884974203"></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
        </div>
    `;
}

/**
 * 336 x 280 Ad banner
 *
 * @returns {string}
 */
export function render336x280Ad(): string {
    return `
        <div class="ads-square">
            <!-- SQUARE 336x280 -->
            <ins class="adsbygoogle"
                style="display:inline-block;width:336px;height:280px"
                data-ad-client="ca-pub-3055942217975997"
                data-ad-slot="7861446092"></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
        </div>
    `;
}

/**
 * @see https://codepen.io/tniezurawski/pen/wvzyVEE
 *
 * @param {string} text
 * @param {string} keyword
 * @returns {string}
 */
export function highlightKeyword(text: string, keyword: string): string {
    const searchValue = new RegExp(keyword, 'ig');
    return text.replace(searchValue, '<span class="caractere">$&</span>');
}

/**
 * Account verification badge
 *
 * @returns {string}
 */
export function certifiedBadge(): string {
    return `
        <img
            class="doc-badge"
            src="${ cdn('images/badge-verified.svg') }"
            title="${ __t('Compte certifié, vérifié par l’équipe Doctori') }"
            alt="${ __t('Compte certifié, vérifié par l’équipe Doctori') }">
    `;
}

/**
 * Empty content placeholder
 *
 * @returns {string}
 */
export function emptyContent(): string {
    return `<div class="d-flex justify-content-center align-items-center w-100" style="height: 70vh">
        <div class="empty-illustration emptyIllustration w-100 d-block text-center ">
            <img src="${asset('images/empty.svg')}" class="mb-3">
            <span class="empty-msg">${__('no_data_available')}</span>
        </div>
    </div>`;
}

/**
 * Get CDN URL
 *
 * @return string
 */
export function cdn(path?: string): string {
    return path ? `${window._app.cdn}/${path}` : window._app.cdn;
}

/**
 * Comma
 *
 * @param  {Locale} locale
 * @return {string}
 */
export function comma(locale?: Locale): string {
    return locale && isAr(locale) ? '، ' : ', ';
}

/**
 * Check if given locale is AR
 *
 * @param {Locale} [locale]
 * @returns {boolean}
 */
export function isAr(locale?: Locale): boolean {
    if (!locale) return false;
    return locale === Locale.AR;
}



/**
 *
 * @param encryptStr
 * @param _key
 * @returns {{Object}}
 */
export function decryptQueryString(encryptStr) {

    let decrypted;

    try {
        let key = process.env.MIX_APP_KEY.substr(7);
        encryptStr = CryptoJS.enc.Base64.parse(encryptStr);
        let encryptData = encryptStr.toString(CryptoJS.enc.Utf8);
        encryptData = JSON.parse(encryptData);
        let iv = CryptoJS.enc.Base64.parse(encryptData.iv);

        decrypted = CryptoJS.AES.decrypt(encryptData.value, CryptoJS.enc.Base64.parse(key), {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        // @ts-ignore
        decrypted = queryStringToObject(CryptoJS.enc.Utf8.stringify(decrypted));
    }
    catch (e) {
        decrypted = {};
    }

    return decrypted;
}

/**
 *
 * @param data
 * @param _key
 * @returns {string}
 */
export function encryptQueryString(data) {

    let key = CryptoJS.enc.Base64.parse(process.env.MIX_APP_KEY.substr(7));

    let iv = CryptoJS.lib.WordArray.random(16);
    let encrypted = CryptoJS.AES.encrypt(data, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    }).toString();

    iv = CryptoJS.enc.Base64.stringify(iv);

    let result = {
        iv: iv,
        value: encrypted,
        mac: CryptoJS.HmacSHA256(iv + encrypted, key).toString()
    }

    result = CryptoJS.enc.Utf8.parse(JSON.stringify(result));

    return CryptoJS.enc.Base64.stringify(result);
}
