import Model from "./Model";
import { Type } from "class-transformer";
import Appointment from './Appointment';
import Stage from '../enums/Stage';

class AppointmentStage extends Model {

    id: number;
    model_type: string;
    model_id: number;
    stage: Stage;
    @Type(() => Date)
    starts_at: Date;
    @Type(() => Date)
    ends_at: Date;
    previous_stage: Stage;
    @Type(() => Date)
    created_at: Date;
    @Type(() => Date)
    updated_at: Date;


    /*
    |--------------------------------------------------------------------------
    | Reflection
    |--------------------------------------------------------------------------
    */

    /**
     * Gets class name
     * survives minification/uglification
     *
     * @return {string}
     */
    getClassName(): string {
        return 'AppointmentStage';
    }


    /*
    |--------------------------------------------------------------------------
    | Relationships
    |--------------------------------------------------------------------------
    */

    @Type(() => Appointment)
    appointment: Appointment;


    /*
    |--------------------------------------------------------------------------
    | Methods
    |--------------------------------------------------------------------------
    */


}

export default AppointmentStage;
