import Model from './Model';
import { Moment } from 'moment';
import Practitioner from './Practitioner';
import BusinessHour from './BusinessHour';
import Establishment from './Establishment';
import { MomentInput } from 'moment-timezone';
import { ClassTransformOptions, plainToClass, Type } from 'class-transformer';

class BusinessDay extends Model {
    id: number;
    practitioner_id: number;
    establishment_id: number;
    day: number; // weekday (0 = Sunday, 1 = Monday, …, 6 = Saturday).
    is_closed: boolean;
    @Type(() => Date)
    created_at: Date;
    @Type(() => Date)
    updated_at: Date;

    private date: Moment;

    /*
    |--------------------------------------------------------------------------
    | Reflection
    |--------------------------------------------------------------------------
    */

    /**
     * Gets class name
     * survives minification/uglification
     *
     * @return {string}
     */
    getClassName(): string {
        return 'BusinessDay';
    }


    /*
    |--------------------------------------------------------------------------
    | Relationships
    |--------------------------------------------------------------------------
    */

    @Type(() => Practitioner)
    practitioner: Practitioner;

    @Type(() => Establishment)
    establishment: Establishment;

    @Type(() => BusinessHour)
    business_hours: BusinessHour[];


    /*
    |--------------------------------------------------------------------------
    | Methods
    |--------------------------------------------------------------------------
    */

    /**
     * Get calendar events for this business day
     *
     * @return \App\Support\Models\Collections\CalendarEventCollection
     */
    calendarEvents() {
        if (! this.isOperating()) {
            return [];
        }

        const events = this.business_hours.map((businessHour: BusinessHour) => {

            // if day date is set
            if (this.dateIsKnown()) {

                const dateString = this.date.format(window.moment.HTML5_FMT.DATE);
                const start      = window.moment(`${dateString} ${businessHour.startTime(window.moment.HTML5_FMT.TIME_SECONDS)}`);
                const end        = window.moment(`${dateString} ${businessHour.endTime(window.moment.HTML5_FMT.TIME_SECONDS)}`);

                businessHour.setStart(start).setEnd(end);
            }

            return businessHour.setPractitionerId(this.practitioner_id)
                .setEstablishmentId(this.establishment_id)
                .calendarEvents();
        }).flat();

        return events;
    }

    /**
     * Check if business day has business hour
     *
     * @return {boolean}
     */
    hasBusinessHours(): boolean {
        return !!this.business_hours.length;
    }

    /**
     * Check if business is operating in this day
     *
     * @return {boolean}
     */
    isOperating(): boolean {
        return this.isOpen();
    }

    /**
     * Check if business is operating in this day
     *
     * @return {boolean}
     */
    isOpen(): boolean {
        return ! this.is_closed;
    }

    /**
     * Check if date is known
     *
     * @return {boolean}
     */
    dateIsKnown(): boolean {
        return !!this.date;
    }

    /**
     * Set day date
     *
     * @param \Illuminate\Support\Carbon|string $date
     * @return \App\Models\BusinessDay
     */
    setDate(date: MomentInput) {
        this.date = window.moment(date);
        return this;
    }

    /**
     * Created date
     *
     * @param {string} format
     * @return {string}
     */
    createdDate(format: string = 'DD MMMM YYYY'): string {
        return window.moment(this.created_at).format(format);
    }

    /**
     * Created time
     *
     * @param {string} format
     * @return {string}
     */
    createdTime(format: string = 'HH[h]mm'): string {
        return window.moment(this.created_at).format(format);
    }

    /**
     * Converts plain (literal) object to class (constructor) object. Also works with arrays.
     */
    static new(plain: [], options?: ClassTransformOptions): BusinessDay[];
    static new(plain: {}, options?: ClassTransformOptions): BusinessDay;
    static new(plain: {} | [], options?: ClassTransformOptions): BusinessDay | BusinessDay[] {
        return plainToClass(BusinessDay, plain as any, options);
    }
}

export default BusinessDay;
