import Enum from "./Enum";

export default class Gender extends Enum {

    /**
     * @var string
     */
    static MALE   = 'm';

    /**
     * @var string
     */
    static FEMALE = 'f';

    /**
     * Check if gender is not valid
     *
     * @param {string} gender
     * @return {boolean}
     */
    static isNotValid(gender) {
        return ! Gender.isValid(gender);
    }

    /**
     * Check if gender is valid
     *
     * @param {string} gender
     * @return {boolean}
     */
    static isValid(gender) {
        return Gender.toValues().includes(gender);
    }

    /**
     * Check if given gender is for male
     *
     * @param gender
     * @return {boolean}
     */
    isMale(gender) {
        return gender === Gender.MALE;
    }

    /**
     * Check if given gender is for female
     *
     * @param gender
     * @return {boolean}
     */
    isFemale(gender) {
        return gender === Gender.FEMALE;
    }
}
