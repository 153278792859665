import Model from './Model';
import Practitioner from './Practitioner';
import Establishment from './Establishment';
import { ClassTransformOptions, plainToClass, Type } from 'class-transformer';

class Folder extends Model {
    id: number;
    name: string;
    slug: string;
    type: string;
    practitioner_id: number;
    establishment_id: number;
    removable: boolean;
    files_count: number;
    @Type(() => Date)
    created_at: Date;
    @Type(() => Date)
    updated_at: Date;

    /*
    |--------------------------------------------------------------------------
    | Reflection
    |--------------------------------------------------------------------------
    */

    /**
     * Gets class name
     * survives minification/uglification
     *
     * @return {string}
     */
    getClassName(): string {
        return 'Folder';
    }

    /*
    |--------------------------------------------------------------------------
    | Relationships
    |--------------------------------------------------------------------------
    */

    @Type(() => Practitioner)
    practitioner: Practitioner;

    @Type(() => Establishment)
    establishment: Establishment;

    /*
    |--------------------------------------------------------------------------
    | Methods
    |--------------------------------------------------------------------------
    */

    /**
     * Check if the folder is removable
     *
     * @returns {boolean}
     */
    isRemovable(): boolean {
        return this.removable;
    }

    /**
     * Check if the folder has files
     *
     * @returns {boolean}
     */
    hasFiles(): boolean {
        return this.files_count > 0;
    }

    /**
     *
     * @returns {number}
     */
    filesCount(): number {
        return this.files_count;
    }

    /**
     * Converts plain (literal) object to class (constructor) object. Also works with arrays.
     */
    static new(plain: [], options?: ClassTransformOptions): Folder[];
    static new(plain: {}, options?: ClassTransformOptions): Folder;
    static new(plain: {} | [], options?: ClassTransformOptions): Folder | Folder[] {
        return plainToClass(Folder, plain as any, options);
    }
}

export default Folder;
