import Model from './Model';
import Folder from './Folder';
import { md5, readableSize } from '../support/utils';
import { Type, plainToClass, ClassTransformOptions } from 'class-transformer';
import { asset } from '../support/helpers';

class Media extends Model {
    id: number;
    model_type: string;
    model_id: number;
    uuid: string;
    collection_name: string;
    name: string;
    file_name: string;
    mime_type: string;
    disk: string;
    conversions_disk: string;
    size: number;
    manipulations: any[];
    custom_properties: any[];
    generated_conversions: any[];
    responsive_images: any[];
    order_column: number;
    @Type(() => Date)
    created_at: Date;
    @Type(() => Date)
    updated_at: Date;
    @Type(() => Date)
    deleted_at: Date;


    /*
    |--------------------------------------------------------------------------
    | Reflection
    |--------------------------------------------------------------------------
    */

    /**
     * Gets class name
     * survives minification/uglification
     *
     * @return {string}
     */
    getClassName(): string {
        return 'Media';
    }


    /*
    |--------------------------------------------------------------------------
    | Relationships
    |--------------------------------------------------------------------------
    */

    @Type(() => Folder)
    folder: Folder;

    /*
    |--------------------------------------------------------------------------
    | Methods
    |--------------------------------------------------------------------------
    */

    getUrl(conversionName?: string): string {

        if (!conversionName) {
            return asset(`${this.getBasePath()}/${this.file_name}`);
        }

        return asset(`${this.getBasePath()}/conversions/${this.trimExtension()}-${conversionName}.${this.extension()}`);
    }

    /**
     * Get a unique base path for the given media.
     *
     * @see https://github.com/spatie/laravel-medialibrary/issues/94#issuecomment-154389833
     * @see https://github.com/spatie/laravel-medialibrary/issues/45#issuecomment-131032664
     *
     * @return {string}
     */
    getBasePath(): string {
        const maxSubdirectories = 1000;
        const id                = this.id;
        const divider           = Math.floor(id / maxSubdirectories);
        const hash              = md5(id);
        return `storage/${this.disk}/${divider}/${hash}`;
    }

    /**
     * Trim extension from filename
     *
     * @returns {string}
     */
    trimExtension(): string {
        return this.file_name.replace(/\.[^/.]+$/, '');
    }

    /**
     * Extract extension from filename
     * @see https://stackoverflow.com/a/12900504/4325011
     *
     * ""                            -->   ""
     * "name"                        -->   ""
     * "name.txt"                    -->   "txt"
     * ".htpasswd"                   -->   ""
     * "name.with.many.dots.myext"   -->   "myext"
     *
     * @returns {String}
     */
    extension(): string {
        return this.file_name.slice((this.file_name.lastIndexOf(".") - 1 >>> 0) + 2);
    }

    /**
     * Human readable size
     *
     * @return string
     */
    readableSize(): string {
        return readableSize(this.size);
    }

    /**
     * Get formatted created date
     *
     * @return string
     */
    createdDate(format: string = 'DD/MM/YYYY') {
        return window.moment(this.created_at).format(format);
    }

    /**
     * Get formatted created time
     *
     * @return string
     */
    createdTime(format: string = 'HH:mm') {
        return window.moment(this.created_at).format(format);
    }

    /**
     * Converts plain (literal) object to class (constructor) object. Also works with arrays.
     */
    static new(plain: [], options?: ClassTransformOptions): Media[];
    static new(plain: {}, options?: ClassTransformOptions): Media;
    static new(plain: {} | [], options?: ClassTransformOptions): Media | Media[] {
        return plainToClass(Media, plain as any, options);
    }
}

export default Media;
