import Echo from 'laravel-echo';
import App from '../app/support/App';
import Pusher from 'pusher-js/types';
import { Moment } from 'moment-timezone';
import { route, toast, __ } from '../app/support/helpers';
import { runtimeTimezone } from '../app/support/utils';
import Timezone from '../app/enums/Timezone';

declare global {
    interface Window {
        _app: any,
        app: App,
        csrf_token: string,
        Echo: Echo,
        Pusher: Pusher,
        moment: Moment,
    }
}

window.app = App.new();

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement;

if (token) {

    window.csrf_token = token.content;

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': token.content
        }
    });
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}


/**
 * https://ned.im/noty/#/installation?id=install-via-yarn
 */

window.Noty = require('noty');

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */


// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     // wsHost: '127.0.0.1',
//     wsHost: window.location.hostname,
//     wsPort: 6001,
//     wssPort: 6001,
//     forceTLS: false,
//     enabledTransports: ['ws', 'wss'],
//     authEndpoint: route('practitioner.broadcasting.auth'),
//     namespace: 'App.Events.Practitioner'
// });


//////////////////////////////////////////////////
// FIREBASE CONFIG - stand by
//////////////////////////////////////////////////


//////////////////////////////////////////////////
// CHECK RUNTIME TIMEZONE
//////////////////////////////////////////////////

setInterval(() => {
    if (!runtimeTimezone(Timezone.AFRICA_CASABLANCA)) {
        toast("Le fuseau horaire de votre navigateur n'est pas défini sur Africa/Casablanca. Veuillez le régler sur Afrique/Casablanca pour vous assurer que toutes les dates s'affichent correctement.").error();
    }
}, 1000*60);

//////////////////////////////////////////////////
// CHECK IF VERFICATION JUST TOOK PLACE
//////////////////////////////////////////////////

setTimeout(() => {
    if (window.app.verificationJustTookPlace()) {
        toast(__('your_email_address_has_been_successfully_verified')).success();
    }
}, 500);
