import Enum from "./Enum";

/**
 * @see {@link https://timetap.atlassian.net/wiki/spaces/TmTapBO/pages/491712/Categorizing+appointments+by+status}
 */
export default class AppointmentStatus extends Enum {

    /**
     * Open status is what every appointment is listed as initially.
     * As soon as you add an appointment, the appointment
     * is added to your schedule and in Open status.
     *
     * @var string
     */
    static OPEN        = 'open';

    /**
     * Booked status is when the patient booked the appointment
     * but not yet confirmed by the staff
     *
     * @var string
     */
    static BOOKED      = 'booked';

    /**
     * An appointment can be confirmed by the staff in the back office or the client.
     *
     * @var string
     */
    static CONFIRMED   = 'confirmed';

    /**
     * A follow-up/check-up/recall appointment
     *
     * @var string
     */
    static FOLLOW_UP   = 'follow_up';

    /**
     * As clients come in for their appointment, you can mark their appointment as Checked In.
     *
     * @var string
     */
    static CHECKED_IN  = 'checked_in';

    /**
     * An appointment that's in Completed status means that the client has come in
     * and the service for the appointment has been successfully rendered.
     *
     * @var string
     */
    static COMPLETED   = 'completed';

    /**
     * In our case i don't think we gon to use this status,
     * when cancelling an appointment it's only means make it open again for the public
     */
    static CANCELLED   = 'cancelled';

    /**
     * It the client does not end up showing up for his/her appointment time,
     * you can mark the appointment as No Show.
     */
    // static NO_SHOW     = 'no_show';

    /**
     * An appointment can only be marked as In Progress by the staff only,
     * and this status helps staff determine when an appointment has started for
     * the purpose of tracking how much time a practitioner spends with a given client.
     */
    static IN_PROGRESS = 'in_progress';

    /**
     * An appointment can be confirmed by the staff in the back office or the client.
     *
     * @var string
     */
    static EXPIRED     = 'expired';
}
