import City from "./City";
import Note from './Note';
import Media from './Media';
import Biometric from './Biometric';
import Gender from '../enums/Gender';
import Appointment from "./Appointment";
import { asset, __ } from '../support/helpers';
import Practitioner from "./Practitioner";
import Prescription from "./Prescription";
import {ClassTransformOptions, Exclude, plainToClass, Type} from "class-transformer";

declare var window: any;

class Patient {
    id: number;
    firstname: string;
    lastname: string;
    cin: string;
    email: string;
    gender: string;
    mobile: string;
    home: string;
    office: string;
    dial_code: string;
    address: string;
    postal_code: string;
    city_id: number;
    creator: string;

    @Type(() => Date)
    birthdate: Date;

    @Type(() => Date)
    verified_at: Date;

    @Type(() => Date)
    created_at: Date;

    @Type(() => Date)
    updated_at: Date;

    @Exclude()
    pivot: object;

    @Exclude()
    $option: HTMLOptionElement;

    /*
    |--------------------------------------------------------------------------
    | Relationships
    |--------------------------------------------------------------------------
    */

    @Type(() => City)
    city: City;

    @Type(() => Appointment)
    appointments: Appointment[];

    @Type(() => Biometric)
    biometrics: Biometric[];

    @Type(() => Appointment)
    before_last_appointment: Appointment;

    @Type(() => Practitioner)
    practitioners: Practitioner[];

    @Type(() => Prescription)
    prescriptions: Prescription[];

    @Type(() => Note)
    notes: Note[];

    @Type(() => Media)
    documents: Media[];

    /*
    |--------------------------------------------------------------------------
    | Methods
    |--------------------------------------------------------------------------
    */

    /**
     * Get full name
     *
     * @return {string}
     */
    fullName() {
        return `${this.firstname} ${this.lastname}`;
    }

    /**
     * Get avatar url
     *
     * @return {string}
     */
    avatar(avatar?: string): string {
        avatar = avatar || `avatar_${this.gender}.svg`;

        return asset(`images/${avatar}`);
    }

    /**
     * Get birthday
     *
     * @param {string} format
     * @return {string}
     */
    getBirthday(format = 'YYYY-MM-DD') {
        return this.hasBirthdate() ? window.moment(this.birthdate).format(format) : null;
    }

    /**
     * Get patient age
     *
     * @return {number|null}
     */
    age() {
        return this.hasBirthdate() ? window.moment().diff(this.birthdate, 'years', false) : null;
    }

    /**
     * Check if patient is male
     *
     * @return boolean
     */
    isMale(): boolean {
        return this.gender === Gender.MALE;
    }

    /**
     * Get long form of Mr/Mrs
     *
     * @return {string}
     */
   longTitle(): string {
        return __(`long_title_${this.gender}`);
    }

    /**
     * Check if patient has specified his gender
     *
     * @returns {boolean}
     */
    hasGender(): boolean {
        return !!this.gender;
    }

    /**
     * Check if birthdate is set
     *
     * @return {boolean}
     */
    hasBirthdate() {
        return !!this.birthdate;
    }

    /**
     * Get gender
     *
     * @return {string}
     */
    getGender() {
        // return Lang.strings(`gender_${this.gender}`);
    }

    /**
     *  Get city name
     *
     * @param {string} [locale]
     * @return {string|null}
     */
    cityName(locale?: string) {
        return this.city?.name(locale);
    }

    /**
     * alias
     *
     * @return {Appointment}
     */
    get beforeLastAppointment() {
        return this.before_last_appointment;
    }

    /**
     * Converts plain (literal) object to class (constructor) object. Also works with arrays.
     */
    static new(plain: [], options?: ClassTransformOptions): Patient[];
    static new(plain: {}, options?: ClassTransformOptions): Patient;
    static new(plain: {} | [], options?: ClassTransformOptions): Patient | Patient[] {
        return plainToClass(Patient, plain as any, options);
    }
}

export default Patient;
