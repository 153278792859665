import Enum from "./Enum";

export default class PractitionerType extends Enum {

    /**
     * @type {String}
     */
    static DOCTOR: string = 'doctor';

    /**
     * @type {String}
     */
    static THERAPIST: string = 'therapist';
}
