import PubSub from 'pubsub-js';
import Timezone from '../enums/Timezone';
import DataUnit from '../enums/DataUnit';
import isString from 'lodash-es/isString';
import isNumber from 'lodash-es/isNumber';
import { default as slugit } from 'slugify';
import HttpStatus from '../enums/HttpStatus';
import { Alphanumeric, EventPayload, SlugifyOptions } from './types';

export function onCaptureClick(selector: string, handler: (e?: Event, payload?: EventPayload) => void, options?: boolean | AddEventListenerOptions): void {
    onClick(selector, handler, true);
}

/**
 * Add click listener to an element
 *
 * @param {string} selector
 * @param {(e?: Event, payload?: EventPayload) => void} handler
 */
export function onClick(selector: string, handler: (e?: Event, payload?: EventPayload) => void, options?: boolean | AddEventListenerOptions): void {

    on(selector, 'click', handler, options);
}

/**
 * Add submit listener to an element
 *
 * @param {string} selector
 * @param {(e?: Event, payload?: EventPayload) => void} handler
 */
export function onSubmit(selector: string, handler: (e?: Event, payload?: EventPayload) => void, options?: boolean | AddEventListenerOptions): void {

    on(selector, 'submit', handler, options);
}

/**
 * Add event listener to an element
 *
 * @param {string} selector
 * @param {string} event
 * @param {(e?: Event, payload?: EventPayload) => void} handler
 */
export function on(selector: string, event: string, handler: (e?: Event, payload?: EventPayload) => void, options?: boolean | AddEventListenerOptions): void {

    document.addEventListener(event, (e: Event) => {

        const eventTarget         = e.target as HTMLElement;
        const target: HTMLElement = eventTarget.closest(selector);
        const isTarget: boolean   = !!target; // "!!" to cast to boolean

        if (! isTarget) return true;

        const payload: EventPayload = {
            target: target
        }

        handler(e, payload);
    }, options);
    // By default, the useCapture flag is set to false which means
    // you handler will only be called during event bubbling phase.
}

/**
 * Dispatch event
 *
 * @param {PubSubJS.Message} topic
 * @param {*} data
 * @returns {boolean}
 */
export function dispatchEvent(topic: PubSubJS.Message, data?: any): boolean {
    return PubSub.publish(topic, data);
}

/**
 * Handle event
 *
 * @param {PubSubJS.Message} topic
 * @param {(data: any) => void} callback
 * @returns {PubSubJS.Token}
 */
export function handleEvent(topic: PubSubJS.Message, callback: (data: any) => void): PubSubJS.Token {
    return PubSub.subscribe(topic, (topic: PubSubJS.Message, data: any) => {
        callback(data);
    });
}

/**
 * wip
 * @param selector
 */
export function toElements(selector: Element | Element[] | string) {
    if (isString(selector)) {
    }
};

/**
 * @see {@link https://www.typescriptlang.org/docs/handbook/mixins.html|typescriptlang.org}
 *
 * @param {*} derivedCtor
 * @param {*} constructors
 */
export function applyMixins(derivedCtor: any, constructors: any[]) {
    constructors.forEach((baseCtor) => {

        let baseCtorPrototype;
        // see comment below
        try {
            baseCtorPrototype = baseCtor.hasOwnProperty('prototype') ? baseCtor.prototype : baseCtor;
        } catch (error) {
            console.warn('baseCtor : ', baseCtor);
            // use https://dzone.com/articles/how-to-write-an-own-bug-tracker-in-javascript
            // console.log('stack : ', error.stack);
            // console.log('error : ', error);
            // console.log('baseCtor : ', baseCtor);
            // console.log('derivedCtor : ', derivedCtor);
        }

        try {
            Object.getOwnPropertyNames(baseCtorPrototype).forEach((name) => {
                // we check if derivedCtor has prototype property or not. I'm not sure that I understand completely
                // this prototype concept, but I noticed that when derivedCtor is a Class (e.g City/Practitioner)
                // in this case it has prototype, in the other hand, when derivedCtor is an instance/object in
                // that case it has no prototype. For the late case, you can see a practical example in
                // resources/assets/js/practitioner/calendar/utils.js@clickedEvent
                let derivedCtorPrototype = derivedCtor.hasOwnProperty('prototype') ? derivedCtor.prototype : derivedCtor;

                name === 'key' && console.log('derivedCtorPrototype : ', derivedCtorPrototype);
                Object.defineProperty(
                    derivedCtorPrototype,
                    name,
                    Object.getOwnPropertyDescriptor(baseCtorPrototype, name) ||
                    Object.create(null)
                );
            });
        } catch (error) {
            // console.log('stack : ', error.stack);
            // console.log('error : ', error);
            // console.log('baseCtor : ', baseCtor);
            // console.log('derivedCtor : ', derivedCtor);
            // console.log('baseCtorPrototype : ', baseCtorPrototype);
        }
    });
}

/**
 * Safe JSON string
 *
 * @param {Object} data
 * @returns {String}
 */
export function stringify(data: object) {
    return JSON.stringify(data, function (key, value) {
        return isString(value) ? convertSingleQuotesToUnicode(value) : value;
    });
}


/**
 * Escape sinlge quotes from as string
 *
 * @see https://gist.github.com/getify/3667624
 * @see https://github.com/Thebarda/Ethstarter/blob/master/utils/utils.js
 * @see https://github.com/pstadler/flightplan/blob/master/lib/utils/index.js
 *
 * @param {String} [value]
 * @returns {String|undefined}
 */
export function escapeSingleQuotes(value?: string): string {
    if (!value) return value;

    if (!value.includes("'")) return value;

    // escape single quote if not already escaped
    return value.replace(/\\([\s\S])|(')/g, "\\$1$2");
}

/**
 * Convert single quotes to unicode
 *
 * @param {String} [value]
 * @returns {String}
 */
export function convertSingleQuotesToUnicode(value?: string): string {
    if (!value) return value;

    if (!value.includes("'")) return value;

    return value.replace(/'/g, "&apos;");
}

/**
 * Human readable file size
 * @see https://stackoverflow.com/a/18650828/4325011
 *
 * note: I think this function should be named readableBytes (size is generic)
 *
 * @param {Number} bytes
 * @param {Number} decimals
 * @returns {String}
 */
export function readableSize(bytes: number): string;
export function readableSize(bytes: number, decimals: number): string;
export function readableSize(bytes: number, decimals: number, withoutUnit: boolean): number;
export function readableSize(bytes: number, decimals: number = 2, withoutUnit: boolean = false): string|number {
    if (bytes === 0) {
        return withoutUnit ? 0 : '0 B';
    }

    const k     = 1024;
    const dm    = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i     = Math.floor(Math.log(bytes) / Math.log(k));

    const size  = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

    return withoutUnit ? size : `${size} ${sizes[i]}`;
}

/**
 * Convert bytes got given data unit
 *
 * @param {number} bytes
 * @param {DataUnit} unit
 * @returns {number}
 */
export function bytesToUnit(bytes: number, unit: DataUnit, decimals: number = 2): number {
    if (bytes === 0) return bytes;

    const k     = 1024;
    const dm    = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'] as DataUnit[];

    const i     = sizes.indexOf(unit);

    const size  = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

    return size;
}


/*
|--------------------------------------------------------------------------
| Request
|--------------------------------------------------------------------------
*/

/**
 * Reload the page
 *
 * @return {void}
 */
export function reload(): void {
    return redirect(document.URL);
}

/**
 * Redirect to a given url
 *
 * @param  {string} url
 * @return {void}
 */
export function redirect(url: string): void {
    // similar behavior as clicking on a link
    window.location.href = url;
}


/*
|--------------------------------------------------------------------------
| Phone Number
|--------------------------------------------------------------------------
*/

/**
 * Check if the given phone number is a valid one
 * PS: Customized for moroccan numbers only
 *
 * @param {string} phoneNumber
 * @return {boolean}
 */
export function isPhoneNumber(phoneNumber?: string): boolean {
    if (!phoneNumber) return false;

    let lineNumber = getLineNumber(phoneNumber);

    return lineNumber.length === 9 && isNumeric(lineNumber);
}

/**
 * Internationalize the phone number
 * PS: Customized for moroccan numbers only
 *
 * @param {string} phoneNumber
 * @return {string}
 */
export function internationalize(phoneNumber?: string): string {
    if (!phoneNumber) return '';

    let dialCode   = '+212';
    let lineNumber = getLineNumber(phoneNumber);

    return `${dialCode}${lineNumber}`;
}

/**
 * Check if the phone number is landline
 *
 * @param {string} phoneNumber
 * @return {boolean}
 */
export function isLandlineNumber(phoneNumber?: string): boolean {
    if (!phoneNumber) return false;

    return getLineNumber(phoneNumber).startsWith('5');
}

/**
 * Get line number (subscriber number)
 * PS: Customized for moroccan numbers only
 *
 * @param {string} phoneNumber
 * @return {string}
 */
export function getLineNumber(phoneNumber?: string): string {
    let lineNumber = '';

    if (phoneNumber.startsWith('+212')) {
        lineNumber = phoneNumber.slice(4);
    } else if (phoneNumber.startsWith('212')) {
        lineNumber = phoneNumber.slice(3);
    } else if (phoneNumber.startsWith('0')) {
        lineNumber = phoneNumber.slice(1);
    }

    return lineNumber;
}

/**
 *  Phone number with readable form
 *
 * @param {string} phoneNumber
 * @param {string} prefix
 * @returns {string}
 */
export function prettifyPhone(phoneNumber: string, prefix?: string): string {
    if (!phoneNumber) return '';

    let toFormat = `${prefix ? prefix : '0'}${getLineNumber(phoneNumber)}`;

    return `${toFormat}`.match(/.{1,2}/g).join(' ');
}


/*
|--------------------------------------------------------------------------
| Math
|--------------------------------------------------------------------------
*/

/**
 * Sequence generator (range)
 * @see https://stackoverflow.com/a/44957114/4325011
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/from#sequence_generator_range
 *
 * @param {number} start
 * @param {number} stop
 * @param {number} step
 * @returns {number[]}
 */
export function range(start: number, stop: number, step?: number): number[] {
    step = step || 1;
    return Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));
}

/**
 * Check if value is not numeric
 *
 * @param value
 * @return {boolean}
 */
export function isNotNumeric(value: any): boolean {
    return !isNumeric(value);
}

/**
 * Returns true if the given value seems to be numeric. Either a number or a string, representing a number
 *
 * @param {*} value
 * @returns {boolean}
 */
export function isNumeric(value: any): boolean {
    return isNumber(value) || isString(value) && (!isNaN(parseInt(value)) || !isNaN(parseFloat(value)));
}


/*
|--------------------------------------------------------------------------
| String
|--------------------------------------------------------------------------
*/

/**
 * Check if the given string is a valid email
 *
 * @param  {*} email
 * @return {boolean}
 */
export function isEmail(email?: any): boolean {
    if (!email) return false;
    var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

/**
 * Slugify
 *
 * @param {string} text
 * @param {SlugifyOptions} [options]
 */
export function slugify(text: string, options?: SlugifyOptions): string {

    let defaults = {
        replacement: '-', // replace spaces with replacement character, defaults to `-`
        remove: undefined, // remove characters that match regex, defaults to `undefined`
        lower: true, // convert to lower case, defaults to `false` (I changed it to true)
        strict: false, // strip special characters except replacement, defaults to `false`
        locale: 'vi', // language code of the locale to use
        trim: true, // trim leading and trailing replacement chars, defaults to `true`
    };

    let _options = Object.assign({}, defaults, options || {});

    return slugit(text, _options);
}

/**
 * Limit the number of characters in a string.
 *
 * @param  {string} value
 * @param  {number} limit
 * @param  {string} [end='...']
 * @return {string}
 */
export function str_limit(value: string, limit: number = 100, end: string = '...'): string {
    return value.length > limit ? value.substring(0, limit) + end : value;
}

/**
 * https://stackoverflow.com/q/42165891/4325011
 *
 * @param {string} value
 * @return {string}
 */
export function base64urlEncode(value: string): string {
    return encodeURIComponent(btoa(value));
}

/**
 * https://stackoverflow.com/q/42165891/4325011
 *
 * @param {string} value
 * @return {string}
 */
export function base64urlDecode(value: string): string {
    return atob(decodeURIComponent(value));
}

/**
 * PHP's md5 in JavaScript
 *
 * @see https://raw.githubusercontent.com/locutusjs/locutus/master/src/php/strings/md5.js
 * @param {Alphanumeric} str
 * @returns {string}
 */
export function md5(str: Alphanumeric): string {
    // let hash
    // try {
    //     const crypto = require('crypto')
    //     const md5sum = crypto.createHash('md5')
    //     md5sum.update(str)
    //     hash = md5sum.digest('hex')
    // } catch (e) {
    //     hash = undefined
    // }

    // if (hash !== undefined) {
    //     return hash
    // }

    let xl

    const _rotateLeft = function (lValue, iShiftBits) {
        return (lValue << iShiftBits) | (lValue >>> (32 - iShiftBits))
    }

    const _addUnsigned = function (lX, lY) {
        let lX4, lY4, lX8, lY8, lResult
        lX8 = (lX & 0x80000000)
        lY8 = (lY & 0x80000000)
        lX4 = (lX & 0x40000000)
        lY4 = (lY & 0x40000000)
        lResult = (lX & 0x3FFFFFFF) + (lY & 0x3FFFFFFF)
        if (lX4 & lY4) {
            return (lResult ^ 0x80000000 ^ lX8 ^ lY8)
        }
        if (lX4 | lY4) {
            if (lResult & 0x40000000) {
                return (lResult ^ 0xC0000000 ^ lX8 ^ lY8)
            } else {
                return (lResult ^ 0x40000000 ^ lX8 ^ lY8)
            }
        } else {
            return (lResult ^ lX8 ^ lY8)
        }
    }

    const _F = function (x, y, z) {
        return (x & y) | ((~x) & z)
    }
    const _G = function (x, y, z) {
        return (x & z) | (y & (~z))
    }
    const _H = function (x, y, z) {
        return (x ^ y ^ z)
    }
    const _I = function (x, y, z) {
        return (y ^ (x | (~z)))
    }

    const _FF = function (a, b, c, d, x, s, ac) {
        a = _addUnsigned(a, _addUnsigned(_addUnsigned(_F(b, c, d), x), ac))
        return _addUnsigned(_rotateLeft(a, s), b)
    }

    const _GG = function (a, b, c, d, x, s, ac) {
        a = _addUnsigned(a, _addUnsigned(_addUnsigned(_G(b, c, d), x), ac))
        return _addUnsigned(_rotateLeft(a, s), b)
    }

    const _HH = function (a, b, c, d, x, s, ac) {
        a = _addUnsigned(a, _addUnsigned(_addUnsigned(_H(b, c, d), x), ac))
        return _addUnsigned(_rotateLeft(a, s), b)
    }

    const _II = function (a, b, c, d, x, s, ac) {
        a = _addUnsigned(a, _addUnsigned(_addUnsigned(_I(b, c, d), x), ac))
        return _addUnsigned(_rotateLeft(a, s), b)
    }

    const _convertToWordArray = function (str) {
        let lWordCount
        const lMessageLength = str.length
        const lNumberOfWordsTemp1 = lMessageLength + 8
        const lNumberOfWordsTemp2 = (lNumberOfWordsTemp1 - (lNumberOfWordsTemp1 % 64)) / 64
        const lNumberOfWords = (lNumberOfWordsTemp2 + 1) * 16
        const lWordArray = new Array(lNumberOfWords - 1)
        let lBytePosition = 0
        let lByteCount = 0
        while (lByteCount < lMessageLength) {
            lWordCount = (lByteCount - (lByteCount % 4)) / 4
            lBytePosition = (lByteCount % 4) * 8
            lWordArray[lWordCount] = (lWordArray[lWordCount] |
                (str.charCodeAt(lByteCount) << lBytePosition))
            lByteCount++
        }
        lWordCount = (lByteCount - (lByteCount % 4)) / 4
        lBytePosition = (lByteCount % 4) * 8
        lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80 << lBytePosition)
        lWordArray[lNumberOfWords - 2] = lMessageLength << 3
        lWordArray[lNumberOfWords - 1] = lMessageLength >>> 29
        return lWordArray
    }

    const _wordToHex = function (lValue) {
        let wordToHexValue = ''
        let wordToHexValueTemp = ''
        let lByte
        let lCount

        for (lCount = 0; lCount <= 3; lCount++) {
            lByte = (lValue >>> (lCount * 8)) & 255
            wordToHexValueTemp = '0' + lByte.toString(16)
            wordToHexValue = wordToHexValue + wordToHexValueTemp.substr(wordToHexValueTemp.length - 2, 2)
        }
        return wordToHexValue
    }

    let x = []
    let k
    let AA
    let BB
    let CC
    let DD
    let a
    let b
    let c
    let d
    const S11 = 7
    const S12 = 12
    const S13 = 17
    const S14 = 22
    const S21 = 5
    const S22 = 9
    const S23 = 14
    const S24 = 20
    const S31 = 4
    const S32 = 11
    const S33 = 16
    const S34 = 23
    const S41 = 6
    const S42 = 10
    const S43 = 15
    const S44 = 21

    str = utf8_encode(str)
    x = _convertToWordArray(str)
    a = 0x67452301
    b = 0xEFCDAB89
    c = 0x98BADCFE
    d = 0x10325476

    xl = x.length
    for (k = 0; k < xl; k += 16) {
        AA = a
        BB = b
        CC = c
        DD = d
        a = _FF(a, b, c, d, x[k + 0], S11, 0xD76AA478)
        d = _FF(d, a, b, c, x[k + 1], S12, 0xE8C7B756)
        c = _FF(c, d, a, b, x[k + 2], S13, 0x242070DB)
        b = _FF(b, c, d, a, x[k + 3], S14, 0xC1BDCEEE)
        a = _FF(a, b, c, d, x[k + 4], S11, 0xF57C0FAF)
        d = _FF(d, a, b, c, x[k + 5], S12, 0x4787C62A)
        c = _FF(c, d, a, b, x[k + 6], S13, 0xA8304613)
        b = _FF(b, c, d, a, x[k + 7], S14, 0xFD469501)
        a = _FF(a, b, c, d, x[k + 8], S11, 0x698098D8)
        d = _FF(d, a, b, c, x[k + 9], S12, 0x8B44F7AF)
        c = _FF(c, d, a, b, x[k + 10], S13, 0xFFFF5BB1)
        b = _FF(b, c, d, a, x[k + 11], S14, 0x895CD7BE)
        a = _FF(a, b, c, d, x[k + 12], S11, 0x6B901122)
        d = _FF(d, a, b, c, x[k + 13], S12, 0xFD987193)
        c = _FF(c, d, a, b, x[k + 14], S13, 0xA679438E)
        b = _FF(b, c, d, a, x[k + 15], S14, 0x49B40821)
        a = _GG(a, b, c, d, x[k + 1], S21, 0xF61E2562)
        d = _GG(d, a, b, c, x[k + 6], S22, 0xC040B340)
        c = _GG(c, d, a, b, x[k + 11], S23, 0x265E5A51)
        b = _GG(b, c, d, a, x[k + 0], S24, 0xE9B6C7AA)
        a = _GG(a, b, c, d, x[k + 5], S21, 0xD62F105D)
        d = _GG(d, a, b, c, x[k + 10], S22, 0x2441453)
        c = _GG(c, d, a, b, x[k + 15], S23, 0xD8A1E681)
        b = _GG(b, c, d, a, x[k + 4], S24, 0xE7D3FBC8)
        a = _GG(a, b, c, d, x[k + 9], S21, 0x21E1CDE6)
        d = _GG(d, a, b, c, x[k + 14], S22, 0xC33707D6)
        c = _GG(c, d, a, b, x[k + 3], S23, 0xF4D50D87)
        b = _GG(b, c, d, a, x[k + 8], S24, 0x455A14ED)
        a = _GG(a, b, c, d, x[k + 13], S21, 0xA9E3E905)
        d = _GG(d, a, b, c, x[k + 2], S22, 0xFCEFA3F8)
        c = _GG(c, d, a, b, x[k + 7], S23, 0x676F02D9)
        b = _GG(b, c, d, a, x[k + 12], S24, 0x8D2A4C8A)
        a = _HH(a, b, c, d, x[k + 5], S31, 0xFFFA3942)
        d = _HH(d, a, b, c, x[k + 8], S32, 0x8771F681)
        c = _HH(c, d, a, b, x[k + 11], S33, 0x6D9D6122)
        b = _HH(b, c, d, a, x[k + 14], S34, 0xFDE5380C)
        a = _HH(a, b, c, d, x[k + 1], S31, 0xA4BEEA44)
        d = _HH(d, a, b, c, x[k + 4], S32, 0x4BDECFA9)
        c = _HH(c, d, a, b, x[k + 7], S33, 0xF6BB4B60)
        b = _HH(b, c, d, a, x[k + 10], S34, 0xBEBFBC70)
        a = _HH(a, b, c, d, x[k + 13], S31, 0x289B7EC6)
        d = _HH(d, a, b, c, x[k + 0], S32, 0xEAA127FA)
        c = _HH(c, d, a, b, x[k + 3], S33, 0xD4EF3085)
        b = _HH(b, c, d, a, x[k + 6], S34, 0x4881D05)
        a = _HH(a, b, c, d, x[k + 9], S31, 0xD9D4D039)
        d = _HH(d, a, b, c, x[k + 12], S32, 0xE6DB99E5)
        c = _HH(c, d, a, b, x[k + 15], S33, 0x1FA27CF8)
        b = _HH(b, c, d, a, x[k + 2], S34, 0xC4AC5665)
        a = _II(a, b, c, d, x[k + 0], S41, 0xF4292244)
        d = _II(d, a, b, c, x[k + 7], S42, 0x432AFF97)
        c = _II(c, d, a, b, x[k + 14], S43, 0xAB9423A7)
        b = _II(b, c, d, a, x[k + 5], S44, 0xFC93A039)
        a = _II(a, b, c, d, x[k + 12], S41, 0x655B59C3)
        d = _II(d, a, b, c, x[k + 3], S42, 0x8F0CCC92)
        c = _II(c, d, a, b, x[k + 10], S43, 0xFFEFF47D)
        b = _II(b, c, d, a, x[k + 1], S44, 0x85845DD1)
        a = _II(a, b, c, d, x[k + 8], S41, 0x6FA87E4F)
        d = _II(d, a, b, c, x[k + 15], S42, 0xFE2CE6E0)
        c = _II(c, d, a, b, x[k + 6], S43, 0xA3014314)
        b = _II(b, c, d, a, x[k + 13], S44, 0x4E0811A1)
        a = _II(a, b, c, d, x[k + 4], S41, 0xF7537E82)
        d = _II(d, a, b, c, x[k + 11], S42, 0xBD3AF235)
        c = _II(c, d, a, b, x[k + 2], S43, 0x2AD7D2BB)
        b = _II(b, c, d, a, x[k + 9], S44, 0xEB86D391)
        a = _addUnsigned(a, AA)
        b = _addUnsigned(b, BB)
        c = _addUnsigned(c, CC)
        d = _addUnsigned(d, DD)
    }

    const temp = _wordToHex(a) + _wordToHex(b) + _wordToHex(c) + _wordToHex(d)

    return temp.toLowerCase()
}

/**
 * PHP's utf8_encode in JavaScript
 *
 * @see https://github.com/locutusjs/locutus/blob/master/src/php/xml/utf8_encode.js
 * @param argString
 * @returns {string}
 */
export function utf8_encode(argString: Alphanumeric): string {

    if (argString === null || typeof argString === 'undefined') {
        return ''
    }

    const string = (argString + '')
    let utftext = ''
    let start
    let end
    let stringl = 0

    start = end = 0
    stringl = string.length
    for (let n = 0; n < stringl; n++) {
        let c1 = string.charCodeAt(n)
        let enc = null

        if (c1 < 128) {
            end++
        } else if (c1 > 127 && c1 < 2048) {
            enc = String.fromCharCode(
                (c1 >> 6) | 192, (c1 & 63) | 128
            )
        } else if ((c1 & 0xF800) !== 0xD800) {
            enc = String.fromCharCode(
                (c1 >> 12) | 224, ((c1 >> 6) & 63) | 128, (c1 & 63) | 128
            )
        } else {
            // surrogate pairs
            if ((c1 & 0xFC00) !== 0xD800) {
                throw new RangeError('Unmatched trail surrogate at ' + n)
            }
            const c2 = string.charCodeAt(++n)
            if ((c2 & 0xFC00) !== 0xDC00) {
                throw new RangeError('Unmatched lead surrogate at ' + (n - 1))
            }
            c1 = ((c1 & 0x3FF) << 10) + (c2 & 0x3FF) + 0x10000
            enc = String.fromCharCode(
                (c1 >> 18) | 240, ((c1 >> 12) & 63) | 128, ((c1 >> 6) & 63) | 128, (c1 & 63) | 128
            )
        }
        if (enc !== null) {
            if (end > start) {
                utftext += string.slice(start, end)
            }
            utftext += enc
            start = end = n + 1
        }
    }

    if (end > start) {
        utftext += string.slice(start, stringl)
    }

    return utftext
}

/**
 * Access object child properties using a dot notation string
 *
 * @see https://stackoverflow.com/a/8052100/4325011
 *
 * @param  {object} object
 * @param  {string} property
 * @return {string}
 */
export function getProperty(object: object, property: string): any {

    var array = property.split(".");

    while (array.length && (object = object[array.shift()]));

    return object;
}

/*
|--------------------------------------------------------------------------
| Events
|--------------------------------------------------------------------------
*/

/**
 * The DOMContentLoaded event fires when the initial HTML document has been completely loaded
 * and parsed, without waiting for stylesheets, images, and sub-frames to finish loading.
 *
 * @param {EventListenerOrEventListenerObject} contentLoadedListener
 * @return {void}
 */
export function ready(contentLoadedListener: EventListenerOrEventListenerObject): void {
    document.addEventListener('DOMContentLoaded', contentLoadedListener);
}

/**
 * The load event is fired when the whole page has loaded, including all dependent resources such as
 * stylesheets and images. This is in contrast to DOMContentLoaded, which is fired as soon as
 * the page DOM has been loaded, without waiting for resources to finish loading.
 *
 * @param {EventListenerOrEventListenerObject} assetsLoadedListener
 * @return {void}
 */
export function loaded(assetsLoadedListener: EventListenerOrEventListenerObject): void {
    window.addEventListener('load', assetsLoadedListener);
}

/*
|--------------------------------------------------------------------------
| DOM
|--------------------------------------------------------------------------
*/

/**
 * Get array of checked elements by name
 * 
 * @param {string} className
 * @return {array}
 */
export function checkedElements(className: string) :Array<HTMLInputElement> {
    return [...Array.from(document.querySelectorAll(`input.${className}[type=checkbox]:checked`) as NodeListOf<HTMLInputElement>)];
}

/**
 * HTML string to HTMLElement
 * @see {@link https://stackoverflow.com/a/35385518/4325011|StackOverFlow}
 *
 * @param {string} html representing a single element
 * @return {HTMLElement}
 */
export function toHtmlElement(html: string): HTMLElement {
    let template = document.createElement('template');

    template.innerHTML = html;

    return template.content.firstElementChild as HTMLElement;
}

/**
 * Returns the first element that is a descendant of node that matches selectors.
 *
 * @param {string} selector
 * @param {HTMLElement} parent
 * @return {HTMLElement}
 */
export function query(selector: string, parent?: HTMLElement): HTMLElement {
    return (parent || document).querySelector(selector);
}

/**
 * Scroll to top
 *
 * @return {void}
 */
export function scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}

/**
 * Check if status code is a server error
 * The server failed to fulfil a request
 * Response status codes beginning with the digit "5".
 *
 * @param {number} statusCode
 * @return {boolean}
 */
export function isServerError(statusCode: number): boolean {
    return statusCode >= HttpStatus.INTERNAL_SERVER_ERROR && statusCode <= HttpStatus.NETWORK_AUTHENTICATION_REQUIRED;
}

/**
 * Check if status code is OK
 *
 * @param {number} statusCode
 * @returns {boolean}
 */
export function responseIsOk(statusCode: number): boolean {
    return statusCode >= HttpStatus.OK && statusCode <= HttpStatus.IM_USED;
}

/*
|--------------------------------------------------------------------------
| Datetime
|--------------------------------------------------------------------------
*/

/**
 * Get/Check runtime timezone
 *
 * @returns {boolean|string}
 */
export function runtimeTimezone(): string;
export function runtimeTimezone(timezone: Timezone): boolean;
export function runtimeTimezone(timezone?: Timezone): boolean|string {
    const runtimeTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timezone ? runtimeTimezone === timezone : runtimeTimezone;
}
