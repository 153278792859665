export function ParseInt(target: any, memberName: string): void {
    let currentValue: any = target[memberName];

    Object.defineProperty(target, memberName, {
        set: (newValue: any) => {
            currentValue = +newValue;
        },
        get: () => currentValue
    });
};
