export default class Enum {

    /**
     * @return {Array}
     */
    static toArray() {
        return Object.entries(this).reduce(function (accumulator, [key, value]) {
            accumulator[key] = value;
            return accumulator;
        }, []);
    }

    /**
     * Get values
     *
     * @return {Array}
     */
    static toValues() {
        return Object.values(this);
    }

    /**
     * Get keys
     *
     * @return {Array}
     */
    static toKeys() {
        return Object.keys(this);
    }
}
