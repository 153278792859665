import Model from './Model';
import { Type } from 'class-transformer';
import TherapeuticClassTranslation from './TherapeuticClassTranslation';

class TherapeuticClass extends Model {
    id: number;
    slug: string;
    @Type(() => Date)
    created_at: Date;
    @Type(() => Date)
    updated_at: Date;

    /*
    |--------------------------------------------------------------------------
    | Reflection
    |--------------------------------------------------------------------------
    */

    /**
     * Gets class name
     * survives minification/uglification
     *
     * @return {string}
     */
    getClassName(): string {
        return 'TherapeuticClass';
    }

    /*
    |--------------------------------------------------------------------------
    | Relationships
    |--------------------------------------------------------------------------
    */

    @Type(() => TherapeuticClassTranslation)
    translation: TherapeuticClassTranslation;

    @Type(() => TherapeuticClassTranslation)
    translations: TherapeuticClassTranslation[];

    /*
    |--------------------------------------------------------------------------
    | Methods
    |--------------------------------------------------------------------------
    */

    // ...
}

export default TherapeuticClass;
