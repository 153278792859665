import City from "./City";
import Media from './Media';
import Model from './Model';
import Locale from '../enums/Locale';
import { comma } from '../support/helpers';
import EstablishmentTranslation from "./EstablishmentTranslation";
import {ClassTransformOptions, plainToClass, Type} from "class-transformer";

class Establishment extends Model {
    id: number;
    uuid: string;
    type: string;
    slug: string;
    email: string;
    is_active: boolean;
    phone_number: string;
    dial_code: string;
    fax: string;
    city_id: number;
    postal_code: string;
    lat: number;
    lng: number;
    @Type(() => Date)
    created_at: Date;
    @Type(() => Date)
    updated_at: Date;


    /*
    |--------------------------------------------------------------------------
    | Reflection
    |--------------------------------------------------------------------------
    */

    /**
     * Gets class name
     * survives minification/uglification
     *
     * @return {string}
     */
     getClassName(): string {
        return 'Establishment';
    }


    /*
    |--------------------------------------------------------------------------
    | Relationships
    |--------------------------------------------------------------------------
    */

    @Type(() => EstablishmentTranslation)
    translation: EstablishmentTranslation;

    @Type(() => EstablishmentTranslation)
    translations: EstablishmentTranslation[];

    @Type(() => City)
    city: City

    @Type(() => Media)
    gallery: Media[];


    /*
    |--------------------------------------------------------------------------
    | Methods
    |--------------------------------------------------------------------------
    */

    /**
     * Get name
     *
     * @param {string} locale
     * @returns {string}
     */
    name(locale?: string): string {
        return (this as any).translateOrDefault(locale)?.name;
    }

    /**
     * Get phone_number
     *
     * @return {string}
     */
    phoneNumber(): string {
        return this.phone_number;
    }

    /**
     * Get full address
     *
     * @param {Locale} locale
     * @return {string}
     */
    fullAddress(locale?: Locale): string {
        const address    = this.address(locale)
        const postalCode = this.postalCode();
        const cityName   = this.cityName(locale);
        const _comma     = comma(locale);

        return `${address}${_comma} ${postalCode}${_comma} ${cityName}`;
    }

    /**
     * Get address
     *
     * @param {Locale} locale
     * @return {string}
     */
    address(locale?: Locale): string {
        return (this as any).translateOrDefault(locale)?.address;
    }

    /**
     * Get postal_code
     *
     * @return {string}
     */
    postalCode(): string {
        return this.postal_code;
    }

    /**
     * Get city name
     *
     * @param {Locale} [locale]
     * @returns string
     */
    cityName(locale?: Locale): string {
        return this.city?.name(locale);
    }

    /**
     * Get city name
     *
     * @param {Locale} [locale]
     * @returns string
     */
    citySlug(locale?: Locale): string {
        return this.city?.slug;
    }

    /**
     * Converts plain (literal) object to class (constructor) object. Also works with arrays.
     */
     static new(plain: [], options?: ClassTransformOptions): Establishment[];
     static new(plain: {}, options?: ClassTransformOptions): Establishment;
     static new(plain: {} | [], options?: ClassTransformOptions): Establishment | Establishment[] {
         return plainToClass(Establishment, plain as any, options);
     }
}

export default Establishment;
