let LangJs   = require('lang.js');
let messages = require('./messages');
import isString from "lodash-es/isString";
import isObject from "lodash-es/isObject";
import { Replacements } from 'lang.js';

const lang = new LangJs({
    messages: messages,
    locale: window._app.locale,
});

/**
 * Get friendly key
 *
 * @param {string} attribute
 * @return {string}
 */
function getFriendlyKey(attribute: string): string {

    let key               = `validation.attributes.${attribute}`;
    let friendlyKey       = lang.get(key);
    let friendlyKeyExists = friendlyKey !== key;
    return friendlyKeyExists ? friendlyKey : attribute;
}

/**
 * Validation
 *
 * @param {string} rule
 * @param {string|object} attribute
 * @return {string}
 */
LangJs.prototype.validation = function (rule: string, attribute?: any ): string {

    let replacements = {};

    if (isString(attribute)) {
        replacements = {'attribute': getFriendlyKey(attribute)};
    }
    else if (isObject(attribute)) {

        let friendly = {};

        for (let [key, value] of Object.entries(attribute)) {
            // @ts-ignore
            friendly[key] = getFriendlyKey(value);
        }

        replacements = friendly;
    }

    return lang.get(`validation.${rule}`, replacements);
}

/**
 * String translation
 *
 * @param {string} key
 * @return {string}
 */
LangJs.prototype.strings = function (key: string, replacements?: Replacements, locale?: string): string {
    return lang.get(`strings.${key}`, replacements, locale);
}


export default lang;
