import Model from './Model';
import Appointment from './Appointment';
import Measurement from './Measurement';
import { Type } from 'class-transformer';
import { applyMixins } from '../support/utils';

class Biometric {
    id: number;
    value: string;
    appointment_id: number;
    measurement_id: number;
    @Type(() => Date)
    created_at: Date;
    @Type(() => Date)
    updated_at: Date;

    /*
    |--------------------------------------------------------------------------
    | Relationships
    |--------------------------------------------------------------------------
    */

    @Type(() => Appointment)
    appointment: Appointment;

    @Type(() => Measurement)
    measurement: Measurement;

    /*
    |--------------------------------------------------------------------------
    | Methods
    |--------------------------------------------------------------------------
    */

    /**
     * Measurement label
     *
     * @returns {string}
     */
    measurementLabel() {
        return this.measurement.label;
    }

    /**
     * Measurement unit
     *
     * @returns {string}
     */
    measurementUnit() {
        return this.measurement.unit;
    }

    /**
     * Created at
     *
     * @param {string} format
     * @return {string}
     */
    createdAt(format?: string) {
        format = format || window.moment.HTML5_FMT.DATETIME_LOCAL_SECONDS;
        return window.moment(this.created_at).format(format);
    }
}

// Then you create an interface which merges
// the expected mixins with the same name as your base
interface Biometric extends Model {}

// Apply the mixins into the base class via
// the JS at runtime
applyMixins(Biometric, [Model]);

export default Biometric;
