import Model from './Model';
import { __ } from '../support/helpers';
import BusinessDay from './BusinessDay';
import CalendarEvent from './CalendarEvent';
import { Moment, MomentInput } from 'moment-timezone';
import AppointmentStatus from '../enums/AppointmentStatus';
import { ClassTransformOptions, plainToClass, Type } from 'class-transformer';

class BusinessHour extends Model {
    id: number;
    business_day_id: number;
    type: string;
    start: MomentInput;
    end: MomentInput;
    @Type(() => Date)
    created_at: Date;
    @Type(() => Date)
    updated_at: Date;

    private practitioner_id: number;
    private establishment_id: number;
    private appointment_duration: number;


    /*
    |--------------------------------------------------------------------------
    | Reflection
    |--------------------------------------------------------------------------
    */

    /**
     * Gets class name
     * survives minification/uglification
     *
     * @return {string}
     */
    getClassName(): string {
        return 'BusinessHour';
    }


    /*
    |--------------------------------------------------------------------------
    | Relationships
    |--------------------------------------------------------------------------
    */

    @Type(() => BusinessDay)
    business_day: BusinessDay;


    /*
    |--------------------------------------------------------------------------
    | Methods
    |--------------------------------------------------------------------------
    */

    /**
     * Get calendar events for this business hour
     *
     * @return \App\Support\Models\Collections\CalendarEventCollection
     */
    calendarEvents() {
        const events    = [];
        const timeSlots = this.timeSlots();

        for (let i = 0; i <= timeSlots.length; i++) {

            // @todo : why timeSlots[$i] is NOT set sometimes, I should figure that out, and write it down
            if ((i in timeSlots) && this.shallNotContinue(timeSlots[i])) {
                break;
            }

            let j = i + 1;
            if (j < timeSlots.length ) {
                // Note that the id here is not unique for this CalendarEvent instance
                // because a business hour can have multiple calendar events
                // those calendar events could share the same business hour id
                events.push(CalendarEvent.new({
                    'id'              : this.id,
                    'practitioner_id' : this.practitioner_id,
                    'establishment_id': this.establishment_id,
                    'modelId'         : this.id,
                    'modelType'       : this.getClassName(),
                    'start'           : timeSlots[i].format('YYYY-MM-DDTHH:mm:ss'),
                    'end'             : timeSlots[j].format('YYYY-MM-DDTHH:mm:ss'),
                    'title'           : __('vacant'),
                    'description'     : 'Time slot description',
                    'textColor'       : '#fff',
                    'classNames'      : [AppointmentStatus.OPEN],
                    'status'          : AppointmentStatus.OPEN,
                    'type'            : this.type,
                    'motive_id'       : null,
                }));
            }
        }

        return events;
    }

    /**
     * Generate time slots
     *
     * @param {number} appointment_duration
     * @return {Moment[]}
     */
    timeSlots(appointment_duration?: number): Moment[] {
        // @ts-ignore
        const range = window.moment().range(this.start, this.end);
        return Array.from(range.by('minutes', {step: appointment_duration || 15})) as Moment[];
    }

    /**
     * Check which time slots to return in the response
     *
     * @param {Moment} eventDate
     * @return bool
     */
    shallNotContinue(eventDate?: Moment) {
        // we need all time slots if the day is today
        // meaning: if it's today, no need to check if it's on the past
        return eventDate.isSame(window.moment(), 'day') // isToday
        // at this point, it's not today, so if this date is in the past, break out
        && eventDate.isBefore();
    }

    /**
     * Get start time
     *
     * @param {string} format
     * @return {string}
     */
    startTime(format?: string): string {
        return window.moment(this.start, window.moment.HTML5_FMT.TIME_SECONDS)
            .format(format || window.moment.HTML5_FMT.TIME);
    }

    /**
     * Get end time
     *
     * @param {string} format
     * @return {string}
     */
    endTime(format?: string): string {
        return window.moment(this.end, window.moment.HTML5_FMT.TIME_SECONDS)
            .format(format || window.moment.HTML5_FMT.TIME);
    }

    /**
     * Set practitioner id
     *
     * @param integer $practitioner_id
     * @return \App\Models\BusinessHour
     */
    setPractitionerId(practitioner_id: number): BusinessHour {
        this.practitioner_id = practitioner_id;
        return this;
    }

    /**
     * Set establishment id
     *
     * @param integer $establishment_id
     * @return \App\Models\BusinessHour
     */
    setEstablishmentId(establishment_id: number): BusinessHour {
        this.establishment_id = establishment_id;
        return this;
    }

    /**
     * Set start
     *
     * @param {Moment} start
     * @return {BusinessHour}
     */
    setStart(start: Moment): BusinessHour {
        this.start = start;
        return this;
    }

    /**
     * Set end
     *
     * @param {Moment} end
     * @return {BusinessHour}
     */
    setEnd(end: Moment): BusinessHour {
        this.end = end;
        return this;
    }

    /**
     * Created date
     *
     * @param {string} format
     * @return {string}
     */
    createdDate(format: string = 'DD MMMM YYYY'): string {
        return window.moment(this.created_at).format(format);
    }

    /**
     * Created time
     *
     * @param {string} format
     * @return {string}
     */
    createdTime(format: string = 'HH[h]mm'): string {
        return window.moment(this.created_at).format(format);
    }

    /**
     * Converts plain (literal) object to class (constructor) object. Also works with arrays.
     */
    static new(plain: [], options?: ClassTransformOptions): BusinessHour[];
    static new(plain: {}, options?: ClassTransformOptions): BusinessHour;
    static new(plain: {} | [], options?: ClassTransformOptions): BusinessHour | BusinessHour[] {
        return plainToClass(BusinessHour, plain as any, options);
    }
}

export default BusinessHour;
