export default class MedicationTranslation {
    id: number;
    uses: string;
    warnings: string;
    dosage: string;
    avoid: string;
    side_effects: string;
    interactions: string;
    locale: string;
    medication_id: number;
}
