import Model from './Model';
import { Type } from 'class-transformer';
import IngredientTranslation from './IngredientTranslation';

class Ingredient extends Model {
    id: number;
    slug: string;
    @Type(() => Date)
    created_at: Date;
    @Type(() => Date)
    updated_at: Date;

    /*
    |--------------------------------------------------------------------------
    | Reflection
    |--------------------------------------------------------------------------
    */

    /**
     * Gets class name
     * survives minification/uglification
     *
     * @return {string}
     */
    getClassName(): string {
        return 'Ingredient';
    }

    /*
    |--------------------------------------------------------------------------
    | Relationships
    |--------------------------------------------------------------------------
    */

    @Type(() => IngredientTranslation)
    translation: IngredientTranslation;

    @Type(() => IngredientTranslation)
    translations: IngredientTranslation[];

    /*
    |--------------------------------------------------------------------------
    | Methods
    |--------------------------------------------------------------------------
    */

    /**
     * Get motive name
     *
     * @param {string} [locale]
     * @return {string}
     */
    name(locale?: string) {
        return (this as any).translateOrDefault(locale)?.name;
    }
}

export default Ingredient;
