import Model from './Model';
import Media from './Media';
import Center from './Center';
import Folder from './Folder';
import Motive from './Motive';
import Locale from '../enums/Locale';
import Speciality from "./Speciality";
import Appointment from './Appointment';
import BusinessDay from './BusinessDay';
import Measurement from './Measurement';
import { Moment, MomentInput } from 'moment-timezone';
import Prescription from './Prescription';
import Establishment from "./Establishment";
import PractitionerSetting from './PractitionerSetting';
import PractitionerType from '../enums/PractitionerType';
import { ShowPractitionerRouteParams } from '../support/types';
import PractitionerTranslation from "./PractitionerTranslation";
import { ClassTransformOptions, plainToClass, Type } from "class-transformer";
import { asset, currentLocale, localizedRoute, parseId, practitionerRoutePrefix } from '../support/helpers';
import CalendarEvent from './CalendarEvent';

class Practitioner extends Model {
    id: number;
    establishment_id: number;
    center_id: number;
    uuid: string;
    slug: string;
    type: string;
    gender: string;
    email: string;
    is_active: boolean;
    @Type(() => Date)
    verified_at: Date;
    @Type(() => Date)
    enrolled_at: Date;
    @Type(() => Date)
    confirmed_at: Date;
    @Type(() => Date)
    certified_at: Date;
    mobile: string;
    dial_code: string;
    consultation_cost: number;
    visits_count: number;
    phone_requests_count: number;
    reviews_count: number;
    rating_average: number;
    facebook: string;
    twitter: string;
    youtube: string;
    @Type(() => Date)
    appointment_at: Date;
    @Type(() => Date)
    created_at: Date;
    @Type(() => Date)
    updated_at: Date;

    // Eloquent withCount/loadCount
    appointments_count: number;

    /*
    |--------------------------------------------------------------------------
    | Reflection
    |--------------------------------------------------------------------------
    */

    /**
     * Gets class name
     * survives minification/uglification
     *
     * @return {string}
     */
    getClassName(): string {
        return 'Practitioner';
    }

    /*
    |--------------------------------------------------------------------------
    | Relationships
    |--------------------------------------------------------------------------
    */

    @Type(() => PractitionerTranslation)
    translation: PractitionerTranslation;

    @Type(() => PractitionerTranslation)
    translations: PractitionerTranslation[];

    @Type(() => Establishment)
    establishment: Establishment;

    @Type(() => Establishment)
    establishments: Establishment[];

    @Type(() => Center)
    center: Center;

    @Type(() => Appointment)
    appointments: Appointment[];

    @Type(() => Speciality)
    speciality: Speciality;

    @Type(() => Speciality)
    specialities: Speciality[];

    @Type(() => Motive)
    motives: Motive[];

    @Type(() => Measurement)
    measurements: Measurement[];

    @Type(() => Prescription)
    prescriptions: Prescription[];

    @Type(() => Prescription)
    prescription_templates: Prescription[];

    @Type(() => Media)
    documents: Media[];

    @Type(() => Media)
    gallery: Media[];

    @Type(() => Media)
    credentials_media: Media[];

    @Type(() => Folder)
    folders: Folder[];

    @Type(() => PractitionerSetting)
    settings: PractitionerSetting[];

    @Type(() => Media)
    avatar: Media;

    @Type(() => BusinessDay)
    business_days: BusinessDay[];

    @Type(() => BusinessDay)
    operating_business_days: BusinessDay[];


    /*
    |--------------------------------------------------------------------------
    | Methods
    |--------------------------------------------------------------------------
    */

    /**
     * Check if the practitioner has at least one bookable availability
     *
     * NOTICE: this is somewhat an expensive method
     *
     * @param {MomentInput} from
     * @param {MomentInput} to
     * @return {CalendarEvent[]}
     */
    hasBookableAvailability(from: MomentInput, to: MomentInput): boolean {
        return !!this.availabilities(from , to).find((availability) => {
            return availability.isBookable();
        });
    }

    /**
     * Check if the practitioner has availabilities
     *
     * NOTICE: this is somewhat an expensive method
     *
     * @param {MomentInput} from
     * @param {MomentInput} to
     * @return {CalendarEvent[]}
     */
    hasAvailabilities(from: MomentInput, to: MomentInput): boolean {
        return !!this.availabilities(from , to).length;
    }

    /**
     * Get availabilities between two dates
     * NB: to support other arguments I could use function overload
     *      for ex:
     *          availabilities({my options})
     *          availabilities(from: string, to: string)
     *      or I could add a third argument as Options object
     *
     * @param {MomentInput} from
     * @param {MomentInput} to
     * @return {CalendarEvent[]}
     */
    availabilities(from: MomentInput, to: MomentInput): CalendarEvent[] {
        from = window.moment(from);
        to   = window.moment(to);
        return this.calendarEvents(from, to).filter((calendarEvent) => {
            const overlaps = this.appointments.filter((appointment) => {
                return appointment.period().overlaps(calendarEvent.period());
            });

            return !overlaps.length; // isEmpty
        });
    }
    /**
     * Get working hours
     *
     * @param {MomentInput} from
     * @param {MomentInput} to
     * @return {CalendarEvent[]}
     */
    calendarEvents(from: MomentInput, to: MomentInput): CalendarEvent[] {

        if (!this.businessDays()) {
            console.log('has no business days');
            // return new CalendarEventCollection();
        }

        // @ts-ignore
        const range  = window.moment().range(from, to);
        const period = Array.from(range.by('days')) as Moment[];

        const events = period.map((date: Moment) => {
            const dayOfWeek = date.day();

            const businessDay = this.businessDays().find((workingDay) => {
                return workingDay.day === dayOfWeek;
            });

            if (businessDay && businessDay.isOperating() && businessDay.hasBusinessHours()) {
                return businessDay.setDate(date).calendarEvents();
            }

            return [];
        }).flat();

        return events;
    }

    /**
     * Get establishment business days
     *
     * @param {Establishment|number} establishment
     * @return {BusinessDay[]}
     */
    businessDays(establishment?: Establishment|number): BusinessDay[] {
        if (! establishment) {
            return this.business_days;
        }

        return this.business_days.filter((businessDay) => {
            return businessDay.establishment_id === parseId(establishment);
        });
    }

    /**
     * Get practitioner prefixed full name
     *
     * @param {Locale} [locale]
     * @return {String}
     */
    prefixedFullName(locale?: Locale): string {
        return `${this.prefix(locale)} ${this.fullName(locale)}`;
    }

    /**
     * Get practitioner prefixed full name
     *
     * @param {Locale} [locale]
     * @return {String}
     */
    prefixedReversedFullName(locale?: Locale): string {
        return `${this.prefix(locale)} ${this.reversedFullName(locale)}`;
    }

    /**
     * Get practitioner prefix
     *
     * @param {Locale} [locale]
     * @return string
     */
    prefix(locale?: Locale): string {
        return this.isDoctor() ? this.prefixByLocale(locale) : '';
    }

    /**
     * Get practitioner's prefix by locale
     *
     * @param {Locale} [locale]
     * @returns {string}
     */
    prefixByLocale(locale?: Locale): string {
        const prefix = { 'ar' : 'ذ.', 'fr' : 'Dr.'};
        return prefix[locale || currentLocale()];
    }

    /**
     * Get full name.
     *
     * @return {String}
     */
    fullName(locale?: string): string {
        return `${this.firstname(locale)} ${this.lastname(locale)}`;
    }

    /**
     * Get reversed full name.
     *
     * @param {Locale} [locale]
     * @return {string}
     */
    reversedFullName(locale?: Locale): string {
        return `${this.lastname(locale)} ${this.firstname(locale)}`;
    }

    /**
     * Get firstname
     *
     * @param {string} [locale]
     * @return {string}
     */
    firstname(locale?: string): string {
        return (this as any).translateOrDefault(locale)?.firstname;
    }

    /**
     * Get lastname
     *
     * @param {string} [locale]
     * @return {string}
     */
    lastname(locale?: string): string {
        return (this as any).translateOrDefault(locale)?.lastname;
    }

    /**
     * Phone number
     *
     * @return {string}
     */
    phoneNumber(): string {
        if (this.relationLoaded('establishment')) {
            return this.currentEstablishment()?.phoneNumber();
        }
        else if (this.relationLoaded('establishments')) {
            return this.establishments[0]?.phoneNumber();
        }
    }

    /**
     * Get latitude
     *
     * @return number
     */
    lat(): number {
        return +this.establishment?.lat;
    }

    /**
     * Get longitude
     *
     * @return number
     */
    lng(): number {
        return +this.establishment?.lng;
    }

    /**
     * Current establishment
     *
     * @param {string} [attribute]
     * @return {Establishment|*}
     */
    currentEstablishment(): Establishment;
    currentEstablishment(attribute: string): any;
    currentEstablishment(attribute?: string): Establishment|any {
        return attribute ? this.establishment[attribute] : this.establishment;
    }

    /**
     * Check if practitioner is of type doctor
     *
     * @return {Boolean}
     */
    isDoctor(): boolean {
        return this.type === PractitionerType.DOCTOR;
    }

    /**
     * Check if practitioner has provided valid credentials (diploma, licence, etc.)
     *
     * @return {boolean}
     */
    isCertified(): boolean {
        return this.certified_at !== null;
    }

    /**
     * Full address
     *
     * @param {Locale} [locale]
     * @return {string}
     */
    fullAddress(locale?: Locale): string {
        return this.currentEstablishment()?.fullAddress(locale);
    }

    /**
     * Get show practitioner details page url
     *
     * @param {ShowPractitionerRouteParams} [params]
     * @return {string}
     */
    showUrl(params?: ShowPractitionerRouteParams): string {
        const defaults: ShowPractitionerRouteParams = {
            prefix: practitionerRoutePrefix(),
            speciality: this.specialitySlug(),
            city: this.citySlug(),
            slug: this.slug,
        };
        return localizedRoute('frontend.practitioners.show', Object.assign(defaults, params));
    }

    /**
     * Get city Name
     *
     * @param {Locale} [locale]
     * @returns {string}
     */
     cityName(locale?: Locale): string {
        return this.currentEstablishment().cityName(locale);
    }

    /**
     * Get city slug
     *
     * @returns {string}
     */
    citySlug(): string {
        return this.currentEstablishment().citySlug();
    }

    /**
     * Get practitioner's speciality name
     *
     * @param {Locale} [locale]
     * @return {string}
     */
    specialityName(locale?: Locale): string {
        if (this.specialityIsLoaded()) {
            return this.speciality.name(locale);
        }
        return this.specialities?.[0]?.name(locale);
    }

    /**
     * Get practitioner's speciality slug
     *
     * @return {string}
     */
    specialitySlug(): string {
        if (this.specialityIsLoaded()) {
            return this.speciality.slug;
        }
        return this.specialities?.[0]?.slug;
    }

    /**
     * Specialities ids
     *
     * @returns {number[]}
     */
    specialityIds(): number[] {
        return this.specialities.map((speciality) => speciality.id);
    }

    /**
     * Get setting instance
     *
     * @param string $key
     * @param mixed $establishment
     * @return \App\Models\PractitionerSetting
     */
    setting(key: string, establishment?: Model|number|string): PractitionerSetting {
        establishment = establishment || this.establishment_id;
        return this.settings.find(setting => setting.key === key && setting.establishment_id === parseId(establishment));
    }

    /**
     * Get practitioner's profile image
     *
     * @return string
     */
    avatarUrl(conversionName?: string, fallback?: string) {
        return this.hasAvatar()
            ? this.getAvatar().getUrl()
            : asset(fallback ?? `images/${this.gender}_doctor_default_photo.svg`);
    }

    /**
     * Get avatar
     *
     * @return {Media}
     */
    getAvatar(): Media;
    getAvatar<T extends Media, K extends keyof Media>(key: T[K]): T[K];
    getAvatar<T extends Media, K extends keyof Media>(key?: T[K]): Media|T[K] {
        // @ts-ignore
        return key ? this.avatar[key] : this.avatar;
    }

    /**
     * Check if practitioner has a profile image.
     *
     * @return boolean
     */
    hasAvatar(): boolean {
        return this.hasOwnProperty('avatar') && this.avatar != null;
    }

    /**
     * Converts plain (literal) object to class (constructor) object. Also works with arrays.
     */
    static new(plain: [], options?: ClassTransformOptions): Practitioner[];
    static new(plain: {}, options?: ClassTransformOptions): Practitioner;
    static new(plain: {} | [], options?: ClassTransformOptions): Practitioner | Practitioner[] {
        return plainToClass(Practitioner, plain as any, options);
    }

    /**
     * Check if speciality relationship is loaded
     *
     * @returns {boolean}
     */
    private specialityIsLoaded(): boolean {
        return this.hasOwnProperty('speciality');
    }
}

export default Practitioner;
