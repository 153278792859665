import {ClassTransformOptions, plainToClass, Type} from "class-transformer";
import { DateRange } from 'moment-range';
import AppointmentType from '../enums/AppointmentType';
import { localizedRoute, moment, now } from '../support/helpers';
import { CalendarEventsByDay } from '../support/types';

class CalendarEvent {

    /**
     * How many minutes BEFORE the calendar event starts
     * the moment we are in this interval, the event cannot be booked
     *
     * @type number
     */
    static CLOSE_DELAY: number = 10;

    id: number;
    practitioner_id: number;
    establishment_id: number;
    modelId: number;
    modelType: string;
    @Type(() => Date)
    start: Date;
    @Type(() => Date)
    end: Date;
    description: string;
    motive_id: number;
    status: string;
    title: string;
    type: string;
    color: string;
    textColor: string;
    className: string;


    /*
    |--------------------------------------------------------------------------
    | Relationships
    |--------------------------------------------------------------------------
    */

    // ...

    /*
    |--------------------------------------------------------------------------
    | Methods
    |--------------------------------------------------------------------------
    */

    /**
     * @return {DateRange}
     */
    period(): DateRange {
        // @ts-ignore
        return moment().range(this.startAt(), this.endAt()) as DateRange;
    }

    /**
     * Booking url
     *
     * @returns {string}
     */
    bookingUrl(queryParams?: object): string {
        const defaults = {
            starts_at: this.startAt(),
            ends_at: this.endAt(),
            practitioner_id: this.practitioner_id,
            establishment_id: this.establishment_id,
        };
        return localizedRoute('frontend.booking.create', Object.assign({}, defaults, queryParams));
    }

    /**
     * Check if calendar event is remote
     *
     * @return {boolean}
     */
    isRemote(): boolean {
        return this.type === AppointmentType.REMOTE || this.type === AppointmentType.BOTH;
    }

    /**
     * Determines if time slot is in the past
     *
     * @return {boolean}
     */
    isExpired(): boolean {
        return moment(this.end).isBefore();
    }

    /**
     * Determines if the time slot can be booked
     *
     * @returns {boolean}
     */
    isBookable(): boolean {
        return (this.timeUntilStart() >= CalendarEvent.CLOSE_DELAY);
    }

    /**
     * Remaining time until the appointment starts
     *
     * @return int
     */
    timeUntilStart() {
        return moment(this.start).diff(now(), 'minutes');
    }

    /**
     * Get start datetime string
     *
     * @param {string} [format]
     * @return {string}
     */
    startAt(format?: string): string {
        format = format || 'YYYY-MM-DDTHH:mm:ss';
        return window.moment(this.start).format(format);
    }

    /**
     * Get end datetime string
     *
     * @param {string} [format]
     * @return {string}
     */
    endAt(format?: string): string {
        format = format || 'YYYY-MM-DDTHH:mm:ss';
        return window.moment(this.end).format(format);
    }

    /**
     * Event date
     *
     * @param {string} [format]
     * @return {string}
     */
    startDate(format?: string): string {
        return this.date(format);
    }

    /**
     * Get start time
     *
     * @param {string} [format]
     * @return {string}
     */
    startTime(format?: string): string {
        format = format || 'HH:mm';
        return window.moment(this.start).format(format);
    }

    /**
     * Day of week - kind of day index in the week array
     *
     * @returns {numner}
     */
    dayOfWeek(): number {
        return moment(this.start).day();
    }

    /**
     * Event date
     *
     * @param {string} [format]
     * @return {string}
     */
    date(format?: string): string {
        format = format || 'YYYY-MM-DD';
        return window.moment(this.start).format(format);
    }

    /**
     * Group given calendar events by day
     *
     * @param {CalendarEvent[]} events
     * @returns {CalendarEventsByDay}
     */
    static groupByDay(events: CalendarEvent[]): CalendarEventsByDay {
        return events.reduce((acc, availability) => {
            const day = moment(availability.start).format('YYYY-MM-DD');
            if (!acc[day]) {
                acc[day] = [];
            }
            acc[day].push(availability);
            return acc;
        }, {});
    }

    /**
     * Converts plain (literal) object to class (constructor) object. Also works with arrays.
     */
    static new(plain: [], options?: ClassTransformOptions): CalendarEvent[];
    static new(plain: {}, options?: ClassTransformOptions): CalendarEvent;
    static new(plain: {} | [], options?: ClassTransformOptions): CalendarEvent | CalendarEvent[] {
        return plainToClass(CalendarEvent, plain as any, options);
    }
}

export default CalendarEvent;
