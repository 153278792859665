export default class PrescriptionMedication {
    prescription_id: number;
    medication_id: number;
    treatment: string;

    /*
    |--------------------------------------------------------------------------
    | Relationships
    |--------------------------------------------------------------------------
    */

    // ...

    /*
    |--------------------------------------------------------------------------
    | Methods
    |--------------------------------------------------------------------------
    */

    // ...
}
