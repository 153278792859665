import optimus from './Optimus';

/**
 * Enocde a number
 *
 * @param {number} id
 * @returns {number}
 */
export function encodeId(id: number): number {
    return optimus.encode(id);
}

/**
 * decode a number
 *
 * @param {number} id
 * @returns {number}
 */
export function decodeId(id: number): number {
    return optimus.decode(id);
}
