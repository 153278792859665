import Model from "./Model";
import Area from '../enums/Area';
import Practitioner from './Practitioner';
import { slugify } from '../support/utils';
import Establishment from './Establishment';
import CenterTranslation from './CenterTranslation';
import {ClassTransformOptions, plainToClass, Type} from "class-transformer";

class Center extends Model {

    id: number;
    uuid: string;
    establishment_id: number;
    slug: string;
    area: Area;
    short_name: string;
    email: string;
    phone_number: string;
    dial_code: string;
    is_active: boolean;
    lat: number;
    lng: number;
    @Type(() => Date)
    created_at: Date;
    @Type(() => Date)
    updated_at: Date;


    /*
    |--------------------------------------------------------------------------
    | Reflection
    |--------------------------------------------------------------------------
    */

    /**
     * Gets class name
     * survives minification/uglification
     *
     * @return {string}
     */
    getClassName(): string {
        return 'Center';
    }


    /*
    |--------------------------------------------------------------------------
    | Relationships
    |--------------------------------------------------------------------------
    */

    @Type(() => Establishment)
    establishment: Establishment;

    @Type(() => Practitioner)
    practitioners: Practitioner[];

    @Type(() => CenterTranslation)
    translation: CenterTranslation;

    @Type(() => CenterTranslation)
    translations: CenterTranslation[];


    /*
    |--------------------------------------------------------------------------
    | Methods
    |--------------------------------------------------------------------------
    */

    name(locale?: string): string {
        return (this as any).translateOrDefault(locale)?.name;
    }

    address(locale?: string): string {
        const address  = (this as any).translateOrDefault(locale)?.address;
        return address ? address : this.name(locale);
    }

    position(locale?: string): string {
        return (this as any).translateOrDefault(locale)?.position;
    }

    isMunicipal(): boolean {
        return this.slug == slugify('Centre Hospitalier Provincial de Ben Guerir');
    }

    /**
     * Converts plain (literal) object to class (constructor) object. Also works with arrays.
     */
    static new(plain: [], options?: ClassTransformOptions): Center[];
    static new(plain: {}, options?: ClassTransformOptions): Center;
    static new(plain: {} | [], options?: ClassTransformOptions): Center | Center[] {
        return plainToClass(Center, plain as any, options);
    }
}

export default Center;
