import pluralize from 'pluralize';
import isString from 'lodash-es/isString';
import snakeCase from 'lodash-es/snakeCase';
import isUndefined from 'lodash-es/isUndefined';
import { currentLocale } from '../support/helpers';
import { convertSingleQuotesToUnicode } from '../support/utils';

abstract class Model {

    /**
     * The table associated with the model.
     *
     * @var string
     */
    protected table: string;

    /**
     * The primary key for the model.
     *
     * @var string
     */
    protected primaryKey = 'id';


    /*
    |--------------------------------------------------------------------------
    | Translatable
    |--------------------------------------------------------------------------
    */

    /**
     * Get translation or default
     *
     * @param {string} [locale]
     */
    translateOrDefault(locale?: string) {
        return this.getTranslation(locale, true);
    }

    /**
     * Get translation
     *
     * @param {string} [locale]
     * @param {boolean} [withFallback]
     */
    getTranslation(locale?: string, withFallback?: boolean) {

        locale = locale || currentLocale();

        return this.getTranslationByLocaleKey(locale);
    }

    /**
     * Get translation by locale key
     *
     * @param {string} key
     */
    getTranslationByLocaleKey(key: string): string {

        if (
            (this as any).relationLoaded('translation')
            && (this as any).translation[this.getLocaleKey()] == key
        ) {
            return (this as any).translation;
        }

        if ((this as any).relationLoaded('translations')) {

            // The find() method returns the value of the first element in
            // the provided array that satisfies the provided testing function.
            // If no values satisfy the testing function, undefined is returned.
            return (this as any).translations.find((translation) => {
                return translation[this.getLocaleKey()] == key;
            });
        }

        return null;
    }

    /**
     * Field name which is used by the translation model.
     *
     * @return {string}
     */
    getLocaleKey(): string {
        return 'locale';
    }

    /**
     * Determine if two models don't have the same ID and don't belong to the same table.
     *
     * @param  {Model} [model]
     * @return {boolean}
     */
    isNot(model?: Model): boolean {
        return !this.is(model);
    }

    /**
     * Determine if two models have the same ID and belong to the same table.
     *
     * @param  {Model} [model]
     * @return {boolean}
     */
    is(model?: Model): boolean {
        return (
            !isUndefined(model) &&
            this.getKey() === model.getKey() &&
            this.getTable() === model.getTable()
        );
    }

    /**
     * Get the value of the model's primary key.
     *
     * @return mixed
     */
    getKey() {
        return this.getAttribute(this.getKeyName());
    }

    /**
     * Get an attribute from the model.
     *
     * @param {string} key
     * @return mixed
     */
    getAttribute(key?: string) {
        if (!key) return;
        return this[key];
    }

    /**
     * Get the primary key for the model.
     *
     * @return string
     */
    getKeyName(): string {
        return this.primaryKey;
    }

    /**
     * Get the table associated with the model.
     *
     * @return {string}
     */
    getTable(): string {
        const className = (this as any).getClassName();
        // console.log('className : ', className);
        const pluralized = pluralize(className);
        // console.log('pluralized : ', pluralized);
        return this.table || snakeCase(pluralized);
    }

    /*
    |--------------------------------------------------------------------------
    | Reflection
    |--------------------------------------------------------------------------
    */

    /**
     * Gets class name
     * survives minification/uglification
     *
     * @return {string}
     */
    abstract getClassName(): string;

    // constructor() {
    //     // super();
    // }

    // test() {
    //     console.log('from based model : ', this);
    //     console.log('super.getAttribute() : ', super.getAttribute());
    // }

    // getKey() {
    //     console.log('overided : ', this);
    //     console.log('super.getKey() : ', super.getKeyName());
    //     return '';
    // }


    /*
    |--------------------------------------------------------------------------
    | HasRelationships
    |--------------------------------------------------------------------------
    */

    /**
     * Determine if the given relation is loaded.
     *
     * @param {string} key
     * @return bool
     */
    relationLoaded(key: string) {
        // The hasOwnProperty() method returns a boolean indicating whether the object
        // has the specified property as its own property (as opposed to inheriting it).
        return this.hasOwnProperty(key);
    }


    /*
    |--------------------------------------------------------------------------
    | Conversations
    |--------------------------------------------------------------------------
    */

    /**
     * Stringify
     *
     * @returns {String}
     */
    stringify(): string {
        return JSON.stringify(this, function (key, value) {
            return isString(value) ? convertSingleQuotesToUnicode(value) : value;
        });
    }
}

export default Model;
