import Model from './Model';
import Patient from "./Patient";
import Medication from './Medication';
import Appointment from "./Appointment";
import Practitioner from "./Practitioner";
import { route } from '../support/helpers';
import Establishment from './Establishment';
import PrescriptionType from '../enums/PrescriptionType';
import {ClassTransformOptions, plainToClass, Type} from "class-transformer";

class Prescription extends Model {
    id: number;
    uuid: string;
    establishment_id: number;
    practitioner_id: number;
    patient_id: number;
    appointment_id: number;
    type: string;
    is_template: boolean;
    template_name: string;
    body: string;
    @Type(() => Date)
    created_at: Date;
    @Type(() => Date)
    updated_at: Date;


    /*
    |--------------------------------------------------------------------------
    | Reflection
    |--------------------------------------------------------------------------
    */

    /**
     * Gets class name
     * survives minification/uglification
     *
     * @return {string}
     */
    getClassName(): string {
        return 'Prescription';
    }


    /*
    |--------------------------------------------------------------------------
    | Relationships
    |--------------------------------------------------------------------------
    */

    @Type(() => Establishment)
    establishment: Establishment;

    @Type(() => Practitioner)
    practitioner: Practitioner;

    @Type(() => Patient)
    patient: Patient;

    @Type(() => Appointment)
    appointment: Appointment;

    @Type(() => Medication)
    medications: Medication[];

    /*
    |--------------------------------------------------------------------------
    | Methods
    |--------------------------------------------------------------------------
    */

    /**
     * Check if a prescription belongs to an appointment
     *
     * @return {boolean}
     */
    belongsToAppointment() {
        return !!this.appointment || !!this.appointment_id;
    }

    /**
     * Download link
     *
     * @return string
     */
    downloadLink() {
        return route('practitioner.prescriptions.download', this.id);
    }

    /**
     * Check if prescription is for pharmacy
     *
     * @return {boolean}
     */
    notForPharmacy() {
        return ! this.forPharmacy();
    }

    /**
     * Check if prescription is for pharmacy
     *
     * @return {boolean}
     */
    forPharmacy() {
        return this.type === PrescriptionType.PHARMACY;
    }

    /**
     * Check if prescription is teplate
     *
     * @return {boolean}
     */
    isTemplate() {
        return this.is_template;
    }

    /**
     * Set content
     *
     * @param {string} body
     * @return {Prescription}
     */
    setBody(body: string) {
        this.body = body;

        return this;
    }

    /**
     * Get created date in readable format
     *
     * @param {string} format
     * @returns {string}
     */
    createdDate(format?: string): string {
        return window.moment(this.created_at).format(format || 'DD/MM/YYYY');
    }

    /**
     * Get created time in readable format
     *
     * @param {string} format
     * @returns {string}
     */
    createdTime(format?: string): string {
        return window.moment(this.created_at).format(format || 'HH:mm');
    }

    /**
     * Get created at
     *
     * @param {string} [format]
     * @return {string}
     */
    createdAt(format?: string) {
        format = format || window.moment.HTML5_FMT.DATETIME_LOCAL_SECONDS;
        return window.moment(this.created_at).format(format);
    }

    /**
     * Converts plain (literal) object to class (constructor) object. Also works with arrays.
     */
    static new(plain: [], options?: ClassTransformOptions): Prescription[];
    static new(plain: {}, options?: ClassTransformOptions): Prescription;
    static new(plain: {} | [], options?: ClassTransformOptions): Prescription | Prescription[] {
        return plainToClass(Prescription, plain as any, options);
    }
}

export default Prescription;
