export default class PractitionerTranslation {

    id: number;
    practitioner_id: number;
    firstname: string;
    lastname: string;
    bio: string;
    credentials: string;
    career: string;
    locale: string;
}
