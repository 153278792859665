import { Hasher } from 'inthash';

class Optimus {

    /**
     * @type {Optimus}
     */
    private static _instance: Optimus;

    /**
     * @type {Hasher}
     */
    private hasher: Hasher;

    /**
     * Constructor
     */
    constructor() {
        if (Optimus._instance) {
            return Optimus._instance;
        }

        this.hasher = new Hasher({
            bits: 53, // Javascript, Number.MAX_SAFE_INTEGER
            prime: process.env.MIX_FAKEID_PRIME,
            inverse: process.env.MIX_FAKEID_INVERSE,
            xor: process.env.MIX_FAKEID_RANDOM,
        });

        Optimus._instance = this;
    }

    /**
     * Enocde a number
     *
     * @param {number} id
     * @returns {number}
     */
    public encode(id: number): number {
        return this.hasher.encode(id);
    }

    /**
     * Decode a number
     *
     * @param {number} id
     * @returns {number}
     */
    public decode(id: number): number {
        return this.hasher.decode(id);
    }
}

const optimus = new Optimus();

export default optimus;
