import Model from './Model';
import Ingredient from './Ingredient';
import { Type } from 'class-transformer';
import Establishment from './Establishment';
import TherapeuticClass from './TherapeuticClass';
import MedicationTranslation from './MedicationTranslation';
import PrescriptionMedication from './PrescriptionMedication';

class Medication extends Model {
    id: number;
    uuid: string;
    generic_name: string;
    brand_name: string;
    pack: string;
    strength: number;
    dosage_unit: string;
    dosage_form: string;
    administration_route: string;
    color: string;
    size: number;
    size_unit: string;
    status: string;
    type: string;
    classification: string;
    atc_code: string;
    pph: number;
    ppv: number;
    refundable: boolean;
    refund_base: number;
    refund_rate: number;
    table: string;
    establishment_id: number;
    @Type(() => Date)
    created_at: Date;
    @Type(() => Date)
    updated_at: Date;


    /*
    |--------------------------------------------------------------------------
    | Reflection
    |--------------------------------------------------------------------------
    */

    /**
     * Gets class name
     * survives minification/uglification
     *
     * @return {string}
     */
    getClassName(): string {
        return 'Medication';
    }

    /*
    |--------------------------------------------------------------------------
    | Relationships
    |--------------------------------------------------------------------------
    */

    @Type(() => Establishment)
    establishment: Establishment;

    @Type(() => MedicationTranslation)
    translation: MedicationTranslation;

    @Type(() => MedicationTranslation)
    translations: MedicationTranslation[];

    @Type(() => Ingredient)
    ingredients: Ingredient[];

    @Type(() => TherapeuticClass)
    therapeuticClasses: TherapeuticClass[];

    @Type(() => PrescriptionMedication)
    pivot: PrescriptionMedication;

    /*
    |--------------------------------------------------------------------------
    | Methods
    |--------------------------------------------------------------------------
    */

    // ...
}

export default Medication;
