import Model from './Model';
import { Type } from 'class-transformer';
import Practitioner from './Practitioner';
import { applyMixins } from '../support/utils';

class Measurement {
    id: number;
    label: string;
    slug: string;
    unit: string;
    practitioner_id: number;
    @Type(() => Date)
    created_at: Date;
    @Type(() => Date)
    updated_at: Date;


    /*
    |--------------------------------------------------------------------------
    | Relationships
    |--------------------------------------------------------------------------
    */

    @Type(() => Practitioner)
    practitioner: Practitioner;


    /*
    |--------------------------------------------------------------------------
    | Methods
    |--------------------------------------------------------------------------
    */

    // ...
}

// Then you create an interface which merges
// the expected mixins with the same name as your base
interface Measurement extends Model {}

// Apply the mixins into the base class via
// the JS at runtime
applyMixins(Measurement, [Model]);

export default Measurement;
