import Model from './Model';
import Patient from './Patient';
import { ClassTransformOptions, plainToClass, Type } from 'class-transformer';
import Practitioner from './Practitioner';

class Note extends Model {
    id: number;
    practitioner_id: number;
    patient_id: number;
    body: string;
    visibility: string;
    @Type(() => Date)
    created_at: Date;
    @Type(() => Date)
    updated_at: Date;


    /*
    |--------------------------------------------------------------------------
    | Reflection
    |--------------------------------------------------------------------------
    */

    /**
     * Gets class name
     * survives minification/uglification
     *
     * @return {string}
     */
    getClassName(): string {
        return 'Note';
    }


    /*
    |--------------------------------------------------------------------------
    | Relationships
    |--------------------------------------------------------------------------
    */

    @Type(() => Practitioner)
    practitioner: Practitioner;

    @Type(() => Patient)
    patient: Patient;


    /*
    |--------------------------------------------------------------------------
    | Methods
    |--------------------------------------------------------------------------
    */

    /**
     * Created date
     *
     * @param {string} format
     * @return {string}
     */
    createdDate(format: string = 'DD MMMM YYYY'): string {
        return window.moment(this.created_at).format(format);
    }

    /**
     * Created time
     *
     * @param {string} format
     * @return {string}
     */
    createdTime(format: string = 'HH[h]mm'): string {
        return window.moment(this.created_at).format(format);
    }

    /**
     * Converts plain (literal) object to class (constructor) object. Also works with arrays.
     */
    static new(plain: [], options?: ClassTransformOptions): Note[];
    static new(plain: {}, options?: ClassTransformOptions): Note;
    static new(plain: {} | [], options?: ClassTransformOptions): Note | Note[] {
        return plainToClass(Note, plain as any, options);
    }
}

export default Note;
