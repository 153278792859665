declare var window: any;
import Model from './Model';
import Motive from "./Motive";
import Center from './Center';
import Patient from "./Patient";
import Guard from '../enums/Guard';
import Stage from '../enums/Stage';
import Biometric from './Biometric';
import { DateRange } from 'moment-range';
import { Moment } from 'moment-timezone';
import { encodeId } from '../lib/hasher';
import Practitioner from './Practitioner';
import Prescription from "./Prescription";
import AppointmentStage from './AppointmentStage';
import AppointmentType from "../enums/AppointmentType";
import { moment, now, route, __, localizedRoute } from '../support/helpers';
import AppointmentStatus from "../enums/AppointmentStatus";
import {ClassTransformOptions, plainToClass, Transform, Type} from "class-transformer";

class Appointment extends Model {

    static ALLOWED_OVERTIME = 10;

    id: number;
    practitioner_id: number;
    establishment_id: number;
    patient_id: number;
    motive_id: number;
    center_id: number;
    @Type(() => Date)
    @Transform(({ value }) => moment(value), { toClassOnly: true })
    starts_at: Moment;
    @Type(() => Date)
    @Transform(({ value }) => moment(value), { toClassOnly: true })
    ends_at: Moment;
    status: string;
    stage: Stage;
    type: string;
    @Type(() => Date)
    @Transform(({ value }) => {return value ? moment(value) : null}, { toClassOnly: true })
    confirmed_at: Moment;
    is_following_up: boolean;
    is_former_visitor: boolean;
    info: string;
    is_paid: boolean;
    @Type(() => Date)
    paid_at: Date;
    paid_amount: number;
    booked_by: string;
    punctuality: number;
    satisfaction: number;
    explanation: number;
    rating_average: number;
    amount: number;
    review_body: string;
    @Type(() => Date)
    reviewed_at: Date;
    review_status: string;
    questioning: string;
    examination: string;
    conclusion: string;
    report: string;
    @Type(() => Date)
    created_at: Date;
    @Type(() => Date)
    updated_at: Date;

    /*
    |--------------------------------------------------------------------------
    | Reflection
    |--------------------------------------------------------------------------
    */

    /**
     * Gets class name
     * survives minification/uglification
     *
     * @return {string}
     */
    getClassName(): string {
        return 'Appointment';
    }

    /*
    |--------------------------------------------------------------------------
    | Relationships
    |--------------------------------------------------------------------------
    */

    @Type(() => Practitioner)
    practitioner: Practitioner;

    @Type(() => Patient)
    patient: Patient;

    @Type(() => Motive)
    motive: Motive;

    @Type(() => Center)
    center: Center;

    @Type(() => Prescription)
    prescriptions: Prescription[];

    @Type(() => Prescription)
    latest_prescription: Prescription

    @Type(() => Biometric)
    biometrics: Biometric[];

    @Type(() => AppointmentStage)
    latest_stage: AppointmentStage;


    /*
    |--------------------------------------------------------------------------
    | Methods
    |--------------------------------------------------------------------------
    */

    /**
     * Get localized type
     *
     * @returns {string}
     */
    getType(): string {
        return __(`appointment_type__${this.type}`);
    }

    /**
     * Determines if the appointment is unreachable
     *
     * @return {boolean}
     */
    isUnreachable(): boolean {
        return !this.isReachable();
    }

    /**
     * Determines if the appointment is still stands
     *
     * @return {boolean}
     */
    isReachable(): boolean {
        return this.isForToday() || this.ends_at.isAfter(); // isAfter(now) means that the appointment is not over yet (the end date is in the future)
    }

    /**
     * Determine if the appointment is for today
     *
     * @returns {boolean}
     */
    isForToday(): boolean {
        return this.starts_at.isSame(now(), 'day');
    }

    /**
     * Check if the appointment is not close
     *
     * @return boolean
     */
    isFarOff() {
        return this.timeUntilStart() > Appointment.ALLOWED_OVERTIME;
    }

    /**
     * Check if the remote appointment could be started
     *
     * @return boolean
     */
    canStart() {
        return (this.timeUntilStart() <= Appointment.ALLOWED_OVERTIME) && !this.overtimeIsOver();
    }

    /**
     * Check appointment allowed overtime is over
     *
     * @return boolean
     */
    overtimeIsOver() {
        return this.elapsedTime() > this.duration() + Appointment.ALLOWED_OVERTIME;
    }

    /**
     * Remaining time until the appointment starts
     *
     * @return int
     */
    timeUntilStart() {
        return moment(this.starts_at).diff(now(), 'minutes');
    }

    /**
     * Remaining time until the appointment ends
     * NB: has the same results as this.remainingTime()
     *
     * @return int
     */
    timeUntilEnd() {
        return moment(this.ends_at).diff(now(), 'minutes');
    }

    /**
     * Remaining time of the occured appointment (in exam room)
     * The remaining time for the session/visit to ends
     *
     * @return int
     */
    remainingTime() {
        return moment(this.ends_at).diff(now(), 'minutes');
    }

    /**
     * Elapsed time since the appointment took place (in exam room)
     * NB: has the same results as this.timeSinceStarted()
     *
     * @return int
     */
    elapsedTime() {
        return now().diff(this.starts_at, 'minutes');
    }

    /**
     * Time since the appointment started
     *
     * @return int
     */
    timeSinceStarted() {
        return now().diff(this.starts_at, 'minutes');
    }

    /**
     * Time since the appointment ended
     *
     * @return int
     */
    timeSinceEnded() {
        return now().diff(this.ends_at, 'minutes');
    }

    /**
     * @return {DateRange}
     */
    period(): DateRange {
        // @ts-ignore
        return moment().range(this.starts_at, this.ends_at) as DateRange;
    }

    /**
     * Get appointment duration in minutes
     *
     * @return int
     */
    duration() {
        return moment(this.ends_at).diff(this.starts_at, 'minutes');
    }

    /**
     * Determines if the appointment is in the past
     *
     * @return {boolean}
     */
    isExpired(): boolean {
        return this.ends_at.isBefore();
    }

    /**
     * Check if appointment "current stage" is stageless
     *
     * @returns {boolean}
     */
    isInStagelessStage(): boolean {
        return this.stage === Stage.STAGELESS;
    }

    /**
     * Check if appointment "current stage" is checked in (ie: waiting room)
     *
     * @returns {boolean}
     */
    isInCheckedInStage(): boolean {
        return this.stage === Stage.CHECKED_IN;
    }

    /**
     * Check if appointment "current stage" is in progress (ie: exam room)
     *
     * @returns {boolean}
     */
    isInProgressStage(): boolean {
        return this.stage === Stage.IN_PROGRESS;
    }

    /**
     * Check if appointment "current stage" is checked out (ie: visit ended)
     *
     * @returns {boolean}
     */
    isInCheckedOutStage(): boolean {
        return this.stage === Stage.CHECKED_OUT;
    }

    /**
     * Get start datetime string
     *
     * @param {string} [format]
     * @return {string}
     */
    startAt(format?: string): string {
        return moment(this.starts_at).format(format || window.moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
    }

    /**
     * Human readable start datetime string
     *
     * @returns {string}
     */
    startAtHumanize(): string {
        return this.starts_at.fromNow();
    }

    /**
     * Start date
     *
     * @param {string} format
     * @return {string}
     */
    startDate(format?: string): string {
        return moment(this.starts_at).format(format || window.moment.HTML5_FMT.DATE);
    }

    /**
     * Start time
     *
     * @param {string} format
     * @return {string}
     */
    startTime(format?: string): string {
        return moment(this.starts_at).format(format || window.moment.HTML5_FMT.TIME);
    }

    /**
     * Get end datetime string
     *
     * @param {string} [format]
     * @return {string}
     */
    endAt(format?: string): string {
        return moment(this.ends_at).format(format || window.moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
    }

    /**
     * End time
     *
     * @param {string} format
     * @return {string}
     */
    endTime(format?: string): string {
        return moment(this.ends_at).format(format || window.moment.HTML5_FMT.TIME);
    }

    /**
     * Get review date
     *
     * @param {string} format
     * @return {string}
     */
    reviewDate(format?: string): string {
        return moment(this.reviewed_at).format(format || window.moment.HTML5_FMT.DATE);
    }

    /**
     * Get review time
     *
     * @param {string} format
     * @return {string}
     */
    reviewTime(format?: string): string {
        return moment(this.reviewed_at).format(format || window.moment.HTML5_FMT.TIME);
    }

    /**
     * Check if appointment is remote
     *
     * @return {boolean}
     */
    isRemote(): boolean {
        return this.type === AppointmentType.REMOTE || this.type === AppointmentType.BOTH;
    }

    /**
     * Check if appointment is face-to-face
     *
     * @return {boolean}
     */
    isInPerson(): boolean {
        return this.type === AppointmentType.IN_PERSON || this.type === AppointmentType.BOTH;
    }

    /**
     * Check if the related patient is a former visitor
     *
     * @return {boolean}
     */
    isFormerPatient() {
        return this.is_former_visitor;
    }

    /**
     * Check if it's a follow up appointment
     *
     * @return {boolean}
     */
    isFollowingUp() {
        return this.is_following_up;
    }

    /**
     * Check for report
     *
     * @return {boolean}
     */
    hasReport() {
        return this.report && !!this.report.trim().length;
    }

    /**
     * Confirm the appointment
     *
     * @return {Appointment}
     */
    confirm(): Appointment {
        this.confirmed_at = now();

        return this;
    }

    /**
     * Put an appointment on hold (A.K.A disconfirm/disprove)
     *
     * @returns {Appointment}
     */
    putOnHold(): Appointment {
        this.confirmed_at = null;
        return this;
    }

    /**
     * Check if an appointment is on hold (not yet confirmed)
     *
     * @returns {boolean}
     */
    isOnHold(): boolean {
        return !this.confirmed_at
    }

    /**
     * Book an appointment
     *
     * @return {Appointment}
     * @deprecated use putOnHold (it's more clear)
     */
    book(): Appointment {
        this.confirmed_at = null;

        return this;
    }

    /**
     * Check if the appointment is not booked
     *
     * @return {boolean}
     * @deprecated use !isOnHold function (it's more clear)
     */
    isNotBooked() {
        return !this.isBooked();
    }

    /**
     * Check if the appointment is booked
     *
     * @return {boolean}
     * @deprecated use isOnHold function (it's more clear)
     */
    isBooked(): boolean {
        return !this.confirmed_at;
    }

    /**
     * Check if the appointment is confirmed by the practitioner
     *
     * @return {boolean}
     */
    isConfirmed(): boolean {
        return !!this.confirmed_at;
    }

    /**
     * Class names
     *
     * @return {array}
     */
    classNames() {
        return [this.status, this.type];
    }

    /**
     * Get motive name
     *
     * @param {string} [locale]
     * @return {string}
     */
    specialityName(locale?: string) {
        return this.motive?.specialityName(locale);
    }

    /**
     * Get motive name
     *
     * @param {string} [locale]
     * @return {string}
     */
    motiveName(locale?: string) {
        return this.motive?.name(locale);
    }

    /**
     * Get patient full name
     *
     * @return {string}
     */
    patientFullName() {
        return this.patient?.fullName();
    }

    /**
     * GET appointment observations
     *
     * @return {string}
     */
    getAppointmentObservationsUrl() {
        return route('practitioner.appointments.observations', this.id);
    }

    /**
     * PUT appointment observations
     *
     * @return {string}
     */
    putAppointmentObservationsUrl() {
        return route('practitioner.appointments.observations', this.id);
    }

    /**
     * Update calendar event url
     *
     * @return {string}
     */
    updateCalendarEventUrl() {
        return route('practitioner.calendar_events.update', this.id);
    }

    /**
     * Update 'calendar event status' url
     *
     * @return {string}
     */
    updateCalendarEventStatusUrl() {
        return route('practitioner.calendar_events.status', this.id);
    }

    /**
     * Reschedule calendar event url
     *
     * @return {string}
     * @deprecated use rescheduleUrl instead (we are rescheduling an appointment not a calendar event)
     */
    rescheduleCalendarEventUrl(guard?: Guard): string {
        guard = guard || Guard.PRACTITIONER;
        return guard === Guard.PRACTITIONER
            ? route(`${guard}.calendar_events.reschedule`, this.id)
            : localizedRoute(`${guard}.calendar_events.reschedule`, this.id);
    }

    /**
     * Destroy calendar event url
     *
     * @return {string}
     */
    destroyCalendarEventUrl() {
        return route('practitioner.calendar_events.destroy', this.id);
    }

    /**
     * Get destroy appointment url
     *
     * @param {Guard} guard
     * @returns {string}
     */
    destroyUrl(guard?: Guard): string {
        guard = guard || Guard.PRACTITIONER;
        return guard === Guard.PATIENT
        ? localizedRoute(`${guard}.appointments.destroy`, this.id)
        : route(`${guard}.appointments.destroy`, this.id);
    }

    /**
     * Reschedule appointment url
     *
     * @return {string}
     */
    rescheduleUrl(guard?: Guard): string {
        guard = guard || Guard.PRACTITIONER;
        return guard === Guard.PRACTITIONER
            ? route(`${guard}.appointments.reschedule`, this.id)
            : localizedRoute(`${guard}.appointments.reschedule`, this.id);
    }

    /**
     * Check if this appointment has no prescriptions
     *
     * @return {boolean}
     */
    hasNoPrescriptions() {
        return ! this.hasPrescriptions();
    }

    /**
     * Check if this appointment has prescriptions
     *
     * @return {boolean}
     */
    hasPrescriptions() {
        return !!this.prescriptions.length;
    }

    /**
     * Check if appointment has latest prescription
     *
     * @returns {boolean}
     */
    hasLatestPrescription(): boolean {
        return !!this.latestPrescription;
    }

    /**
     * Unset 'latest prescription' relation
     *
     * @returns {Appointment}
     */
    unsetLatestPrescription(): Appointment {
        this.latest_prescription = null

        return this;
    }

    /**
     * Telemedicine short link
     *
     * @returns {string}
     */
    teleLink(guard?: Guard): string {
        guard = guard || Guard.PATIENT;
        return route(`frontend.telemedicine.${guard}`, btoa(this.id.toString()));
    }

    /**
     * Get token
     *
     * @returns {number}
     */
    token(): number {
        return encodeId(this.id);
    }

    /**
     * Get last appointment prescription
     *
     * @return {Prescription}
     */
    get latestPrescription(): Prescription {
        return this.latest_prescription;
    }

    /**
     * Get latest stage
     *
     * @return {Prescription}
     */
    get latestStage(): AppointmentStage {
        return this.latest_stage;
    }

    /**
     * Get appointment measurements
     *
     * @return {Array<Measurement>}
     */
    get measurements() {
        return this.biometrics.map((biometric) => biometric.measurement);
    }

    /**
     * Converts plain (literal) object to class (constructor) object. Also works with arrays.
     */
    static new(plain: [], options?: ClassTransformOptions): Appointment[];
    static new(plain: {}, options?: ClassTransformOptions): Appointment;
    static new(plain: {} | [], options?: ClassTransformOptions): Appointment | Appointment[] {
        return plainToClass(Appointment, plain as any, options);
    }
}

export default Appointment;
