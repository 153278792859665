export default class EstablishmentTranslation {

    id: number;
    name: string;
    address: string;
    position: string;
    presentation: string;
    practical_information: string;
    locale: string;
    establishment_id: number;
}
