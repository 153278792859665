import Enum from './Enum';

export default class PrescriptionType extends Enum {

    /**
     * @var string
     */
    static PHARMACY    = 'pharmacy';

    /**
     * @var string
     */
    static BIOLOGY     = 'biology';

    /**
     * @var string
     */
    static IMAGERY     = 'imagery';

    /**
     * @var string
     */
    static PARAMEDICAL = 'paramedical';

    /**
     * Check if given type is not for pharmacy
     *
     * @param {String} type
     * @returns {boolean}
     */
    static isNotPharmacy(type: string): boolean {
        return ! this.isPharmacy(type);
    }

    /**
     * Check if given type is for pharmacy
     *
     * @param {String} type
     * @returns {boolean}
     */
    static isPharmacy(type: string): boolean {
        return type.toLowerCase() === this.PHARMACY;
    }
}
