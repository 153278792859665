import Model from "./Model";
import {ClassTransformOptions, plainToClass, Type} from "class-transformer";
import SpecialityTranslation from "./SpecialityTranslation";
import Motive from './Motive';
import Practitioner from './Practitioner';

class Speciality extends Model {

    id: number;
    uuid: string;
    slug: string;
    @Type(() => Date)
    created_at: Date;
    @Type(() => Date)
    updated_at: Date;


    /*
    |--------------------------------------------------------------------------
    | Reflection
    |--------------------------------------------------------------------------
    */

    /**
     * Gets class name
     * survives minification/uglification
     *
     * @return {string}
     */
    getClassName(): string {
        return 'Speciality';
    }


    /*
    |--------------------------------------------------------------------------
    | Relationships
    |--------------------------------------------------------------------------
    */

    @Type(() => SpecialityTranslation)
    translation: SpecialityTranslation;

    @Type(() => SpecialityTranslation)
    translations: SpecialityTranslation[];

    @Type(() => Practitioner)
    practitioners: Practitioner[];

    @Type(() => Motive)
    motives: Motive[];


    /*
    |--------------------------------------------------------------------------
    | Methods
    |--------------------------------------------------------------------------
    */

    /**
     * Get speciality name
     *
     * @param {string} [locale]
     * @return {string}
     */
    name(locale?: string) {

        return (this as any).translateOrDefault(locale)?.name;
    }

    /**
     * Converts plain (literal) object to class (constructor) object. Also works with arrays.
     */
    static new(plain: [], options?: ClassTransformOptions): Speciality[];
    static new(plain: {}, options?: ClassTransformOptions): Speciality;
    static new(plain: {} | [], options?: ClassTransformOptions): Speciality | Speciality[] {
        return plainToClass(Speciality, plain as any, options);
    }
}

export default Speciality;
